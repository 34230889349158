.dropdown {
  cursor: pointer;
}
#default_document_Approved {
  display: none;
}

#default_document_Approve {
  display: none;
}
#default_document_Declined {
  display: none;
}

#default_document_Pending {
  display: none;
}

#default_document_All {
  display: none;
}

div.moveDocument:hover {
  background: #e5e5e5;
  cursor: pointer;
}

.DocumentOutlineNone {
  outline: none;
}
#digital_form_page ul li {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.top_mrg_digital_forms {
  top: 120px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .top_mrg_digital_forms {
    top: 205px;
  }
}
.tab_table {
  display: table;
  margin: 0 auto;
}
.horizontal_list_tab .nav {
  border-bottom: none !important;
  list-style: none;
}
.horizontal_list_tab li {
  display: inline;
}
.horizontal_list_tab li.active a {
  border: none !important;
  border-right: 1px solid #d3d3d3 !important;
  color: #29429d !important;
  vertical-align: middle;
}
.horizontal_list_tab li.active a span.badge {
  background-color: #29429d;
  vertical-align: middle;
}
.horizontal_list_tab li a {
  border-right: 1px solid #d3d3d3;
  color: #87868a;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 25px;
  vertical-align: middle;
}
.horizontal_list_tab li:last-child a {
  border-right: none !important;
}
.horizontal_list_tab li:last-child a {
  border-right: none;
}
.horizontal_list_tab li a .badge {
  background-color: #87868a;
  border-radius: 50%;
  color: #fff;
  height: 25px;
  line-height: 25px;
  margin-left: 5px;
  padding: 0px 2px !important;
  text-align: center;
  vertical-align: middle;
  width: 25px;
}
.searchInput_form .search_input {
  border-radius: 5px !important;
  font-size: 15px !important;
  height: 47px;
  outline: 0 !important;
  padding-left: 40px;
}
.searchInput_form .search_button {
  background: transparent !important;
  border: none;
  display: block;
  float: left;
  height: 47px;
  outline: none;
  position: absolute;
  width: 47px;
}
.position_relative {
  bottom: -16px;
  height: 47px;
  position: relative;
}
.inline_list_icons .btn_plus_cusotm {
  border: none !important;
  display: block;
  padding: 0px !important;
}
.df_tabs .draft_Table thead tr th {
  padding: 10px 0px;
  vertical-align: middle;
}
.df_tabs .draft_Table .tbl_checkbox {
  height: 20px;
  position: relative;
  width: 20px;
}
.df_tabs .draft_Table .tbl_checkbox input[type="checkbox"] {
  background: white !important;
  border: none !important;
  bottom: 0px;
  display: block;
  height: 17px;
  padding: 0px;
  position: absolute;
  width: 17px;
}
.df_tabs
  .draft_Table
  .tbl_checkbox
  input[type="checkbox"]:checked
  + label::after {
  bottom: 0;
  color: #29429d;
  content: "\f00c";
  font-family: "FontAwesome";
  font-weight: normal;
  font-size: 18px;
  left: -6px;
  position: absolute;
  right: 0;
  text-indent: 0.3em;
  top: 0px;
}
.df_tabs .draft_Table tbody .tbl_checkbox input[type="checkbox"] {
  border: 1px solid #d3d3d3 !important;
}
.df_tabs .draft_Table tbody tr td {
  vertical-align: middle !important;
}
.dropdown_menu_user3 > li > a {
  display: block;
  padding: 3px 10px;
}
.dropdown_menu_users_down li a {
  color: #86878a;
  font-size: 16px;
  padding-top: 10px;
}
.dropdown_menu_users_down li a svg {
  fill: #acacac;
  height: 16px;
  margin-bottom: -3px;
  margin-right: 5px;
  margin-top: 5px;
  width: 16px;
}
#toggle_data_table p {
  border-bottom: 1px solid #fafafa;
  color: #7b7b7b;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  padding: 10px 0px;
}
#toggle_data_table {
  margin-bottom: 0px;
}
#toggle_data_table a {
  color: #1d2175;
  font-size: 15px;
  font-weight: 600;
}
#toggle_data_table span {
  color: #5a5a5a;
  font-size: 16px;
  font-weight: 600;
}
.pending_lbl {
  color: #ffde92;
  font-size: 15px;
  font-weight: 600;
}
.reject_lbl {
  color: #ff4343;
  font-size: 15px;
  font-weight: 600;
}
.accept_lbl {
  color: #46c56c;
  font-size: 15px;
  font-weight: 600;
}
#musicinfo_1 {
  display: none;
}
.hide_activity_heading a {
  color: #1d2175 !important;
  font-size: 15px;
  font-weight: 600;
}
.hide_activity_heading {
  background-color: transparent !important;
  border-color: transparent !important;
}
#panel_heading_margin .help_panel_group {
  margin-bottom: 0px !important;
}
.tbl_mrg_bottom {
  margin-bottom: 0px !important;
}
.border_bottom_none {
  border-bottom: none;
}
.digital_form_table_bdr {
  border: none !important;
}
.digital_form_table_bdr tr {
  border: none !important;
}
.digital_form_table_bdr tr {
  border: none !important;
}
.digital_form_table_bdr td table td table {
  border: solid 1px #ccc !important;
}
.digital_form_table_bdr td table td table td {
  border-bottom: solid 1px #ccc !important;
}
#toggle_data_table .panel-heading [data-toggle="collapse"].collapsed:after {
  color: #1d2175;
  float: left;
  font-weight: 100;
  margin-right: 10px;
}
#toggle_data_table .panel-heading [data-toggle="collapse"]:after {
  color: #1d2175;
  float: left;
  font-weight: 100;
  margin-right: 10px;
}
#toggle_data_table .panel-heading [data-toggle="collapse"].collapsed:after {
  color: #989898;
  content: "\f067";
  moz-transform: rotate(90deg);
  ms-transform: rotate(90deg);
  o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
#toggle_data_table .panel-heading [data-toggle="collapse"].collapsed:after {
  color: #1d2175 !important;
}
#toggle_data_table .panel-heading [data-toggle="collapse"]:after {
  color: #989898;
  content: "\f068";
  float: right;
  font-family: "fontawesome";
  font-size: 14px;
  line-height: 22px;
  moz-transform: rotate(-0deg);
  ms-transform: rotate(-0deg);
  o-transform: rotate(-0deg);
  transform: rotate(-0deg);
  -webkit-transform: rotate(-0deg);
}
#toggle_data_table .panel-heading [data-toggle="collapse"]:after {
  color: #1d2175 !important;
  float: left;
}
#btn_plus_digital .btn_plus_cusotm {
  margin-right: 10px !important;
}
.dropdown-menu {
  /*	left: inherit;
	min-width: 110px;
	right: 8px;
	top: 0px;*/
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  #myCarousel_pdf .carousel-caption {
    left: 0%;
    margin: 0 auto;
    right: 0%;
    text-align: center;
  }
  .badge_custom {
    top: 63px !important;
  }
  .file_viewer {
    padding: 20px 0px;
  }
  #digital_form_page .horizontal_list_tab li a {
    border-right: none;
    padding: 10px 20px;
  }
  #digital_form_page .horizontal_list_tab li {
    float: left;
  }
  .horizontal_list_tab li.active a {
    border-right: none !important;
  }
  #digital_form_toggle .dropdown-menu {
    left: inherit;
    right: -3px;
  }
  #digital_form_toggle .approvel_new_open_table td table tr td {
    font-size: 13px;
  }
}
.approvel_field_table td img {
  margin-top: 5px;
}
.modal-dialog {
  margin: 30px auto;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .x_img_pack {
    height: 16px;
    margin-top: -3px !important;
    width: 14px;
  }
  .approvel_field_table {
    border: none;
    width: 500px;
  }
}
#musicinfo_1 {
  display: none;
}
.dpdown {
  display: none;
}
#digital_form_page .nav-tabs > li > a {
  border: none;
  border-radius: 0px;
  border-right: 1px solid #d3d3d3;
  margin-right: 0px;
}
#digital_form_page .nav-tabs > li.active > a,
#digital_form_page .nav-tabs > li.active > a:hover,
#digital_form_page .nav-tabs > li.active > a:focus {
  border: none;
  margin: 0px;
}
#digital_form_page .nav-tabs > li > a:hover {
  background: transparent;
  color: #29429d !important;
}
#approve_box select {
  appearance: none;
  background-color: transparent;
  background-image: url(../../assets/images/down_arrow_amprvoe.png);
  background-origin: content-box;
  background-position: right;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: none;
  font-size: 14px;
  height: 38px;
  margin-bottom: 20px;
  moz-appearance: none;
  padding: 5px 8px;
  padding-left: 10px;
  -webkit-appearance: none;
  width: 100%;
}
#approve_box .searchInput_form .search_input {
  border: 1px solid #ccc;
  border-radius: 5px !important;
  font-size: 13px !important;
  height: 38px;
}
#approve_box .searchInput_form .search_button {
  height: 37px;
  width: 39px;
}
#approve_box .searchInput_form .search_input {
  padding-left: 30px;
}
#approve_box .nav-tabs {
  border-bottom: none;
}
#approve_box .nav > li > a {
  border: 1px solid #c4c4c4;
  color: #86878a;
  font-size: 20px;
  height: 37px;
  margin: 0px;
  padding: 7px;
  width: 33px;
}
#approve_box .nav-tabs > li.active > a,
#approve_box .nav-tabs > li.active > a:hover,
#approve_box .nav-tabs > li.active > a:focus {
  background-color: #29429d;
  color: #fff;
}
#approve_box .nav-tabs > li > a:hover {
  background-color: #29429d;
  color: #fff;
}
.tab_border_1 {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 0px !important;
}
.tab_border_2 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
}
.grid_layout {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 20fr 20fr;
  padding: 0px 10px;
}
.grid_layout_content {
  border-bottom: 1px solid #f0f0f0;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 30fr 25fr;
  padding: 11px 15px 5px 15px;
}
.grid_layout_content.active {
  background: #e8ebf4;
}
.grid_layout_content:hover {
  background: #e8ebf4;
  transition: 0.4s;
}
.grid_layout_content h3 {
  color: #5a5a5a;
  font-size: 20px;
  font-weight: 600;
  margin-top: 0px;
}
.grid_layout_content p {
  color: #5a5a5a;
  font-size: 17px;
  font-weight: 400;
}
.grid_layout_content a:hover {
  color: #5a5a5a;
  text-decoration: underline;
}
.grid_layout_content a {
  color: #5a5a5a;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}
.sidebar_main {
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  padding: 0px;
}
.approve_tick span {
  color: #46c56c;
  font-size: 20px;
  font-weight: 600;
}
.decline span {
  color: #86878a;
  font-size: 20px;
  font-weight: 600;
}
.approve_tick svg {
  fill: #46c56c;
  position: relative;
  top: 1px;
  width: 20px;
}
.decline svg {
  fill: #86878a;
  position: relative;
  top: 1px;
  width: 20px;
}
.slider_grid_layout {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 40fr 35fr 30fr;
  padding: 25px 15px;
}
.slider_grid_inner {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 35fr 30fr;
}
.file_viewer button {
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  width: 45px;
  height: 34px;
  background: #fff;
  margin: 0 10px;
}
.message_svg {
  position: relative;
  top: 1px;
}
.message_svg svg {
  fill: #acacac;
  position: relative;
  top: 1px;
  width: 20px;
}
.page_prev_next {
  text-align: right;
}
.page_prev_next span {
  color: #7b7b7b;
  font-size: 17px;
  margin: 0px 10px;
}
.page_prev_next svg {
  fill: #989898;
  height: 20px;
  position: relative;
  top: 5px;
  width: 20px;
}
.badge_custom {
  background: #c4c4c4;
  border-radius: 50%;
  color: #fff;
  height: 20px;
  position: absolute;
  width: 20px;
  top: 15px;
}

.slider_pdf_viewer p {
  color: #5a5a5a;
  font-size: 21px;
  font-weight: 600;
}
.slider_pdf_viewer p span {
  color: #5a5a5a;
  font-size: 17px;
  font-weight: 400;
}
.slider_pdf_viewer {
  border: 1px solid #e3e3e3;
  border-radius: 1px;
}
.slider_pdf_viewer h3 {
  color: #5a5a5a;
  font-size: 25px;
  font-weight: 600;
  margin-top: 30%;
}
#myCarousel_pdf .carousel-caption {
  bottom: 20px;
  color: #000;
  padding-bottom: 20px;
  padding-top: 20px;
  position: inherit !important;
  text-align: center;
  text-shadow: none;
  z-index: 10;
}
#myCarousel_pdf .carousel-control.left {
  background-image: none;
}
#myCarousel_pdf .carousel-control.right {
  background-image: none;
}
#myCarousel_pdf .carousel-indicators li {
  background-color: #fff !important;
  border: 1px solid #d6d6d6 !important;
  height: 15px !important;
  margin: 0 4px !important;
  width: 15px !important;
}
#myCarousel_pdf .carousel-indicators .active {
  background-color: #d6d6d6 !important;
  height: 15px !important;
  margin: 0 !important;
  width: 15px !important;
}
#myCarousel_pdf .carousel-indicators {
  top: 10px !important;
}
#myCarousel_pdf .carousel-inner {
  margin-top: 15px !important;
  padding-top: 25px !important;
}
.sidebar_main_right {
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  float: left;
  overflow: hidden;
  padding: 0px;
  min-height: calc(100vh - 200px);
}
.toggle_button_div {
  background: #f9f9f9;
  text-align: center;
  width: 1.333333% !important;
}
.toggle_button_div a {
  color: #86878a;
  font-size: 20px;
  left: 0%;
  position: absolute;
  right: 0%;
  top: 50%;
}
@media only screen and (min-width: 320px) and (max-width: 414px) {
  .grid_layout_content h3 {
    font-size: 15px;
  }

  .grid_layout_content {
    padding: 11px 5px 5px 5px;
  }

  .grid_layout_content p {
    font-size: 14px;
  }
  .grid_layout {
    display: block;
  }
  .sidebar_main {
    width: 100% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid_layout_content {
    padding: 15px 5px 5px 5px;
  }
  .grid_layout_content p {
    font-size: 12px;
  }
  .grid_layout {
    display: block;
  }
  .slider_grid_layout {
    grid-template-columns: 50fr 35fr 33fr;
    padding: 25px 5px;
  }
  .approve_tick span {
    font-size: 15px;
  }
  .decline span {
    bottom: 4px;
    font-size: 16px;
    position: relative;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .slider_grid_layout {
    display: block;
  }
  .toggle_button_div {
    display: none !important;
  }
  .sidebar_main_right {
    margin-top: 15px;
    width: 100%;
  }
}
@media only screen and (min-width: 1025px){
  .sidebar_main_right {
    /* height: 855px; */
  }
  .toggle_button_div {
    height: 855px;
  }
  .slider_pdf_viewer {
    min-height: 855px;
    /*height: 100%;*/
    /*overflow-y: scroll;*/
  }
}
@media only screen and (min-width: 768px) {
  .toggle_button_div {
    display: block !important;
  }
  .sidebar_main {
    width: 32% !important;
  }
  #myCarousel_pdf .carousel-caption {
    left: 0%;
    right: 0%;
    margin: 0 auto;
  }
  #digital_form_toggle .hide_show_panel {
    padding: 0px 15px 0px 35px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1130px) {
  .grid_layout_content h3 {
    font-size: 17px;
  }
  .sidebar_main_right [data-slide="prev"] {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1330px) {
  .grid_layout_content h3 {
    font-size: 17px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .toggle_button_div {
    height: 555px;
  }
  .sidebar_main_right {
    /* height: 555px; */
  }
  .grid_layout_content h3 {
    font-size: 13px;
  }
  .page_prev_next span {
    font-size: 14px;
    margin: 0px;
  }
  .horizontal_list_tab li a {
    padding: 10px 10px;
  }
  .grid_layout_content a {
    font-size: 12px;
  }
  .sidebar_main_right [data-slide="prev"] {
    margin-right: 0px;
  }
}
.digital_form_approve {
  width: 97% !important;
}
.activity_pop1 svg {
  fill: #29429d;
  height: 16px;
  width: 25px;
}
.active_pop_label {
  border-bottom: solid 1px #ebebeb;
  color: #7b7b7b;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 15px;
  padding-bottom: 5px;
  padding-left: 0px;
  text-align: left;
}
.active_pop_label strong {
  color: #5a5a5a;
}
.modal_digi_form_attach {
  padding-left: 30px;
  padding-right: 30px;
}
.modal_digi_form_attach table th {
  background: #eeeeee;
  color: #7b7b7b;
  font-family: Segoe UI;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  padding: 8px;
}
.modal_digi_form_attach table td {
  background: #fff;
  border-bottom: solid 1px #e3e3e3;
  color: #7b7b7b;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
}
.modal_digi_form_attach table {
  border: solid 1px #e3e3e3;
}
.attach_imgsvg svg {
  height: 24px !important;
  margin-bottom: -3px;
  width: 24px !important;
}
.textarea_imprt_data {
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  color: #989898;
  height: 200px;
  padding: 10px;
  width: 100%;
}
.digi_attach_pop_area4 {
  border: solid 1px #e4e4e4 !important;
}
.digi_attach_pop_area4 th {
  background: #e3e3e3 !important;
  color: #86878a;
  font-size: 15px;
  font-weight: 600 !important;
  padding: 8px;
  padding-left: 20px !important;
}
.digi_attach_pop_area4 td {
  background: #fff !important;
  border: none !important;
  border-bottom: solid 1px #e4e4e4 !important;
  color: #86878a;
  font-size: 15px;
  font-weight: 600 !important;
  padding: 8px;
  padding-left: 20px !important;
  padding-right: 15px;
}
.digi_kevin_plum_poptable {
}
.digi_kevin_plum_poptable td {
  color: #656565;
  font-size: 15px;
  font-weight: normal !important;
  padding: 8px;
  padding-left: 0px;
}
.digi_kevin_plum_poptable5 {
  border: solid 1px #e4e4e4 !important;
}
.digi_kevin_plum_poptable5 th {
  background: #e3e3e3 !important;
  color: #86878a;
  font-size: 15px;
  font-weight: 600 !important;
  padding: 8px;
  padding-left: 20px !important;
}
.digi_kevin_plum_poptable5 td {
  background: #fff !important;
  border: none !important;
  border-bottom: solid 1px #e4e4e4 !important;
  color: #86878a;
  font-size: 15px;
  font-weight: 600 !important;
  padding: 8px;
  padding-left: 20px !important;
  padding-right: 15px;
}
.comment_box_pop_digi6 {
  border: solid 1px #e3e3e3;
  border-radius: 5px;
  height: 300px;
  overflow-y: scroll;
  padding: 10px;
}
.comment_box_pop_digi6::-webkit-scrollbar {
  width: 8px;
}
.comment_box_pop_digi6::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.comment_box_pop_digi6::-webkit-scrollbar-thumb {
  background: #888;
}
.comment_box_pop_digi6::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.comment_box_labelarea6 {
  border-bottom: solid 1px #e3e3e3;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.comment_box_labelarea6 .label1 {
  color: #357aae;
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
}
.comment_box_labelarea6 .label2 {
  color: #86878a;
  font-size: 14px;
  line-height: normal;
}
.comment_box_labelarea6 .label3 {
  color: #86878a;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
}
.textarea_comment_add_pop7 {
  border: solid 1px #e3e3e3;
  border-radius: 5px;
  color: #86878a;
  font-size: 15px;
  height: 83px;
  line-height: normal;
  padding: 5px;
  width: 100%;
}
.modal_pop_digi_comment8 {
  height: 500px;
  overflow-y: scroll;
}
.btn_add_comment9 {
  background: #4358a7;
  border: solid 1px #357aae;
  border-radius: 5px !important;
  color: #fff;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 600;
  height: 34px !important;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  width: 140px !important;
}
.btn_add_comment9:hover {
  background: #fff;
  border: solid 1px #357aae;
  color: #357aae;
}
.modal_decline_hdr {
  background: #4358a7;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}
.modal_decline_hdr svg {
  fill: #fff;
  height: 80px;
  width: 80px;
}
.decline_pop_close {
  height: 14px;
  margin-right: 10px;
  margin-top: 36px;
  width: 14px;
}
.decline_img_pop svg {
  color: #ccc;
  fill: #ccc;
  height: 16px;
  left: 28px;
  position: absolute;
  top: 10px;
  width: 16px;
}
.decline_textraea_contact {
  background: #fff;
  border: solid 1px #e6e6e6;
  border-radius: 5px;
  box-shadow: none;
  color: #707070;
  font-size: 15px;
  height: 150px !important;
  outline: none !important;
  padding-left: 40px;
}
.bdr_none_9 {
  border: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .modal_digi_form_attach {
    height: auto;
    overflow: inherit;
  }
  .digi_kevin_plum_poptable5 {
    margin-bottom: 10px !important;
  }
  .general_setting_hd select {
    margin-top: 0px !important;
  }
}
.last_child_border {
  border-bottom: none !important;
}
.horizontal_list_tab li {
  padding: 0px;
}
.horizontal_list_tab li a {
  border-right: 1px solid #d3d3d3;
  color: #87868a;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 20px !important;
  vertical-align: middle;
}
@media (max-width: 767px) and (min-width: 320px) {
  #digital_form_page {
    padding-top: 60px;
  }
  .top_mrg_general {
    padding-top: 60px !important;
  }
  .emailtemplates_btn_plus_cusotm {
    margin-right: 5px !important;
  }
}
.displayNo {
  display: none !important;
}
.carousel_custom {
  border-bottom: 1px solid black;
}

.documentDetailLoader {
  width: 30px;
  height: 30px;
}

@media only screen and (min-width: 768px) {
  #myModal_more_detail .modal-dialog {
    width: 55%;
  }
}

#myModal_more_detail .table-approver-document {
  table-layout: fixed;
}

#myModal_more_detail .table-approver-document tbody tr td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowwrap;
}

.document_sidebar_scroll {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.carousel-title {
  margin-top: 24px;
}

.pdf-doc-title {
  position: relative;
}

.pdf-doc-title .btn {
  position: absolute;
  right: 20px;
  top: 0px;
  background-color: #fff;
  border: 1px solid #eee;
  outline: none;
}

.pdf-doc-title .btn .badge_custom {
  top: -11px !important;
}
