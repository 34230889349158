.users_OutlineNone {
  outline: none;
}
.mt15 {
  margin-top: 15px;
}
.mt22 {
  margin-top: 22px;
}
.btn_user_down_icon {
  background-color: transparent !important;
  border: none;
}
.dropdown_menu_users_down {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}
.dropdown_menu_users_down li a {
  color: #86878a;
  font-size: 16px;
  padding-top: 10px;
}
.dropdown_menu_users_down li a:hover {
  background-color: transparent !important;
}
.dropdown_menu_users_down li a svg {
  width: 16px;
  height: 16px;
  fill: #acacac;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: -3px;
}
.dropdown_menu_user3 {
  position: absolute;
  top: 0%;
  left: -97px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100px;
  padding: 2px 0;
  margin: 2px 0 0;
  box-shadow: none;
}
.dropdown_menu_user3 > li > a {
  display: block;
  padding: 3px 10px;
}
.user_pop_1 {
  height: 500px;
  overflow-y: scroll;
}
.user_pop_1 h2 {
  border-bottom: solid 1px #e3e3e3;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  color: #656565;
  font-size: 18px;
  font-weight: 600;
}
.modal-dialog_users {
  font-size: 15px !important;
  width: 650px;
}
.check_box_pop {
  font-size: 14px;
  font-weight: normal;
  float: left;
  padding-left: 0px;
}
.checkmark_box {
  top: 10px;
}
.checkmark_box_auth {
  top: 20px;
}
.users_checkmark {
  top: 10%;
  left: 104% !important;
}
.check_box_pop .checkmark:after {
  left: 7px;
  top: 3px;
}
.width_check_form_dtl_pop {
  width: 32%;
  float: left;
}
.width_check_form_dtl_pop2 {
  width: 34%;
  float: left;
}
.width_check_form_dtl_pop2 .checkmark {
  left: 103px;
}
.width_check_form_dtl_pop3 {
  width: 28%;
  float: left;
}
.width_check_form_dtl_pop3 .checkmark {
  left: 90px;
}
.width_check_form_dtl_pop4 {
  width: 25%;
  float: left;
}
.width_check_form_dtl_pop5 {
  width: 22%;
  float: left;
}
.width_check_form_dtl_pop5 .checkmark {
  left: 40px;
}
.width_check_form_dtl_pop6 {
  width: 30%;
  float: left;
}
.width_check_form_dtl_pop6 .checkmark {
  left: 69px;
}
.width_check_form_dtl_pop7 {
  width: 30%;
  float: left;
}
.mrg_top_from8 {
  margin-top: 7px;
}
.left0_check {
  left: 0px !important;
}
.text_area_user {
  border: solid 1px #e6e6e6;
  color: #656565;
  font-size: 15px;
  border-radius: 0px;
  box-shadow: none;
  padding-left: 10px;
  height: 80px;
  background: #fff;
  width: 100%;
  outline: none;
}
.user_label_email {
  font-size: 14px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .user_sign_img {
    width: 91px !important;
    max-width: 91px;
  }
  .modal-dialog_users {
    font-size: 15px !important;
    width: 96%;
  }
  .check_box_pop .checkmark:after {
    left: 7px;
    top: 3px;
  }
  .width_check_form_dtl_pop {
    width: 100%;
    float: left;
  }
  .width_check_form_dtl_pop2 {
    width: 100%;
    float: left;
  }
  .width_check_form_dtl_pop2 .checkmark {
    left: 107px;
  }
  .width_check_form_dtl_pop3 {
    width: 100%;
    float: left;
  }
  .width_check_form_dtl_pop3 .checkmark {
    left: 70px;
  }
  .width_check_form_dtl_pop4 {
    width: 100%;
    float: left;
  }
  .width_check_form_dtl_pop5 {
    width: 50%;
    float: left;
  }
  .width_check_form_dtl_pop5 .checkmark {
    left: 40px;
  }
  .width_check_form_dtl_pop6 {
    width: 50%;
    float: left;
  }
  .width_check_form_dtl_pop6 .checkmark {
    left: 69px;
  }
  .width_check_form_dtl_pop7 {
    width: 50%;
    float: left;
  }
  .approvel_new_open_table table th {
    font-size: 12px !important;
    white-space: nowrap;
    padding-left: 6px !important;
  }
  .custom_field_tab td {
    padding-left: 6px !important;
  }
  .label_csutom_pop {
    clear: both;
  }
  .mrg_bot_custom10 {
    clear: both;
  }
}
.users_plus_icon_table {
  background: #ffffff;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  color: #989898 !important;
  margin-right: 15px !important;
  outline: none !important;
}
.users_plus_icon_table:hover {
  background: #ffffff;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  color: #989898 !important;
}
.users_plus_icon_table:focus {
  background: #ffffff;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  color: #989898 !important;
}
#approverGroupOfUser {
  height: inherit !important;
}
.users_cursorpointer {
  cursor: pointer;
}
.users_width_check_form_dtl_pop2 {
  width: 33% !important;
}
.user_validation {
  color: #ff0000;
  margin-bottom: 0px;
  font-size: 12px;
  float: right;
  position: absolute;
  right: 0;
  top: 32px;
  right: 15px;
}

.btn_addgroup_pop {
  margin-top: 10px;
}

@media (min-width: 320px) and (max-width: 500px) {
  .users_width_check_form_dtl_pop2 {
    width: 50% !important;
  }
}
