@font-face {
     font-family: 'Segoe UI';
     src: url('../fonts/SegoeUI-Semibold.eot');
     src: url('../fonts/SegoeUI-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/SegoeUI-Semibold.woff2') format('woff2'), url('../fonts/SegoeUI-Semibold.woff') format('woff'), url('../fonts/SegoeUI-Semibold.ttf') format('truetype'), url('../fonts/SegoeUI-Semibold.svg#SegoeUI-Semibold') format('svg');
     src: local('Segoe UI Semibold'), local('SegoeUI-Semibold');
     font-weight: 600;
     font-style: normal;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-Light.eot');
     src: url('../fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Light.woff2') format('woff2'), url('../fonts/SourceSansPro-Light.woff') format('woff'), url('../fonts/SourceSansPro-Light.ttf') format('truetype'), url('../fonts/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
     font-weight: 300;
     font-style: normal;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-Bold.eot');
     src: url('../fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Bold.woff2') format('woff2'), url('../fonts/SourceSansPro-Bold.woff') format('woff'), url('../fonts/SourceSansPro-Bold.ttf') format('truetype'), url('../fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
     font-weight: bold;
     font-style: normal;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-Semibold.eot');
     src: url('../fonts/SourceSansPro-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Semibold.woff2') format('woff2'), url('../fonts/SourceSansPro-Semibold.woff') format('woff'), url('../fonts/SourceSansPro-Semibold.ttf') format('truetype'), url('../fonts/SourceSansPro-Semibold.svg#SourceSansPro-Semibold') format('svg');
     font-weight: 600;
     font-style: normal;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-BoldIt.eot');
     src: url('../fonts/SourceSansPro-BoldIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-BoldIt.woff2') format('woff2'), url('../fonts/SourceSansPro-BoldIt.woff') format('woff'), url('../fonts/SourceSansPro-BoldIt.ttf') format('truetype'), url('../fonts/SourceSansPro-BoldIt.svg#SourceSansPro-BoldIt') format('svg');
     font-weight: bold;
     font-style: italic;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-Black.eot');
     src: url('../fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Black.woff2') format('woff2'), url('../fonts/SourceSansPro-Black.woff') format('woff'), url('../fonts/SourceSansPro-Black.ttf') format('truetype'), url('../fonts/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
     font-weight: 900;
     font-style: normal;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-Regular.eot');
     src: url('../fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Regular.woff2') format('woff2'), url('../fonts/SourceSansPro-Regular.woff') format('woff'), url('../fonts/SourceSansPro-Regular.ttf') format('truetype'), url('../fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
     font-weight: normal;
     font-style: normal;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-ExtraLightIt.eot');
     src: url('../fonts/SourceSansPro-ExtraLightIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-ExtraLightIt.woff2') format('woff2'), url('../fonts/SourceSansPro-ExtraLightIt.woff') format('woff'), url('../fonts/SourceSansPro-ExtraLightIt.ttf') format('truetype'), url('../fonts/SourceSansPro-ExtraLightIt.svg#SourceSansPro-ExtraLightIt') format('svg');
     font-weight: 200;
     font-style: italic;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-ExtraLight.eot');
     src: url('../fonts/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-ExtraLight.woff2') format('woff2'), url('../fonts/SourceSansPro-ExtraLight.woff') format('woff'), url('../fonts/SourceSansPro-ExtraLight.ttf') format('truetype'), url('../fonts/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
     font-weight: 200;
     font-style: normal;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-BlackIt.eot');
     src: url('../fonts/SourceSansPro-BlackIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-BlackIt.woff2') format('woff2'), url('../fonts/SourceSansPro-BlackIt.woff') format('woff'), url('../fonts/SourceSansPro-BlackIt.ttf') format('truetype'), url('../fonts/SourceSansPro-BlackIt.svg#SourceSansPro-BlackIt') format('svg');
     font-weight: 900;
     font-style: italic;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-LightIt.eot');
     src: url('../fonts/SourceSansPro-LightIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-LightIt.woff2') format('woff2'), url('../fonts/SourceSansPro-LightIt.woff') format('woff'), url('../fonts/SourceSansPro-LightIt.ttf') format('truetype'), url('../fonts/SourceSansPro-LightIt.svg#SourceSansPro-LightIt') format('svg');
     font-weight: 300;
     font-style: italic;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-SemiboldIt.eot');
     src: url('../fonts/SourceSansPro-SemiboldIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-SemiboldIt.woff2') format('woff2'), url('../fonts/SourceSansPro-SemiboldIt.woff') format('woff'), url('../fonts/SourceSansPro-SemiboldIt.ttf') format('truetype'), url('../fonts/SourceSansPro-SemiboldIt.svg#SourceSansPro-SemiboldIt') format('svg');
     font-weight: 600;
     font-style: italic;
}
 @font-face {
     font-family: 'Source Sans Pro';
     src: url('../fonts/SourceSansPro-It.eot');
     src: url('../fonts/SourceSansPro-It.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-It.woff2') format('woff2'), url('../fonts/SourceSansPro-It.woff') format('woff'), url('../fonts/SourceSansPro-It.ttf') format('truetype'), url('../fonts/SourceSansPro-It.svg#SourceSansPro-It') format('svg');
     font-weight: normal;
     font-style: italic;
}
 .se-pre-con {
 position: fixed;
 left: 0px;
 top: 0px;
 width: 100%;
 height: 100%;
 z-index: 9999;
 background: url(../images/Preloader.gif) center no-repeat #ffffffde;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
 .se-pre-con {
 position: fixed;
 left: 0px;
 top: 0px;
 width: 100%;
 height: 100%;
 z-index: 9999;
background: url(../images/Preloader.gif);
background-position:  center;
background-repeat: no-repeat;
background-color: rgba(255, 255, 255, 0.8);
}
}
 .modal_w_dashedit {
     width:750px;
}
 .dash_proifle_edit_pop {
     background:#F9F9F9;
     width:100%;
     min-height:460px;
     padding:20px;
}
 .user_edit_white_box {
     background:#fff;
     border-radius:5px;
     padding:30px;
     text-align:center;
}
 .user_edit_white_box svg {
     width:80px;
     height:80px;
     margin:auto;
     fill:#E3E3E3 !important;
     color:#E3E3E3 !important;
}
 .user_edit_white_box path {
     fill:#E3E3E3 !important;
     color:#E3E3E3 !important;
}
 .btn_edit_avator_dash {
     margin: auto;
     background: #D6D6D6;
     padding: 3px;
     border-radius: 5px;
     padding-left: 20px;
     padding-right: 20px;
     color: #fff;
     border: solid 1px #D6D6D6;
     font-family: Source Sans Pro !important;
     font-weight: normal !important;
     font-size: 16px;
}
 .btn_edit_avator_dash:hover {
     color: #000;
     border: solid 1px #D6D6D6;
     background:#fff;
}
 .edit_dash_pop_rightarea {
     padding:20px;
     padding-left:0px;
     padding-right:0px;
     font-size: 14px !important;
     color: #656565;
     font-family: Segoe UI !important;
     font-weight: normal;
}
 .edit_dash_pop_rightarea input {
     border: solid 1px #E6E6E6;
     color: #656565;
     font-size: 15px;
     border-radius: 0px;
     box-shadow: none;
     padding-left: 10px;
     height: 33px;
     background: #fff;
     width: 100%;
     outline: none;
}
 .pdg_30 {
     padding:30px;
}
 .hdr_border_none {
     border:none !important;
}
 .mrg_top5_minus {
     margin-top:-5px;
}
 .mrg_top5 {
     margin-top:5px;
}
 .mrg_top10 {
     margin-top:10px;
}
 .svg_img_project svg {
     width:20px;
     height:20px;
     fill:#989898;
}
 .add_file_btn {
     border:solid 1px #29429D;
     font-size:17px;
     font-weight:normal;
     color:#29429D !important;
     font-family:Segoe UI;
     text-align:center;
     padding:5px;
     padding-left:30px;
     padding-right:30px;
     border-radius:5px;
     background-color:transparent !important;
}
 .btn_adduser_pck_pop {
     border:solid 1px #29429D;
     font-size:17px;
     font-weight:normal;
     color:#29429D !important;
     font-family:Segoe UI;
     text-align:center;
     padding:5px;
     padding-left:20px;
     padding-right:20px;
     border-radius:5px;
}
 .btn_adduser_pck_pop:hover {
     border:solid 1px #29429D;
     color:#fff !important;
     background:#29429D;
}
 .table_formpck_pop_1 table {
     border:solid 1px #E3E3E3;
}
 .table_formpck_pop_1 table th {
     background:#EEEEEE;
     font-size:14px;
     font-weight:600;
     color:#7B7B7B;
     font-family:Segoe UI;
     padding: 8px;
}
 .table_formpck_pop_1 table td {
     font-size:13px;
     font-weight:normal;
     color:#7B7B7B;
     font-family:Segoe UI;
     padding: 8px;
     border-bottom:solid 1px #E3E3E3;
}
 .custome_form_pck_hd h2 {
     border-bottom:solid 1px #E3E3E3;
     padding-bottom:10px;
     margin-bottom:20px;
     width:100%;
     font-size:18px;
     font-weight:600;
     color:#656565;
     font-family:Segoe UI;
}
 .mrg_check_form_pack {
     margin-top:6px;
}
 .email_temp_box {
     border:solid 1px #E3E3E3;
     min-height:600px;
     font-weight:600;
     color:#7B7B7B;
     font-size:24px;
     text-align:center;
}
 .mrg_top_email_temp {
     margin-top:250px;
}
 .email_temp_hdr {
     border-bottom:solid 1px #E3E3E3;
     padding-top:10px;
     padding-bottom:10px;
     height:60px;
}
 .email_temp_hdr label {
     float:left;
     font-size: 16px;
     color: #656565;
     font-weight: bold;
     font-family:Segoe UI;
     text-align:left;
     font-weight:normal;
     margin-right:20px;
     margin-top:3px;
}
 .email_hdr_select {
     float:left;
     margin-right:20px;
	 width: 161px;

}
 .email_hdr_select select {
     float:left;
     font-size: 14px;
     color: #656565;
     font-weight: bold;
     font-family:Segoe UI;
     text-align:left;
     font-weight:normal;
     margin-right:20px;
     border:solid 1px #E3E3E3;
     width:100%;
     height:30px;
	 padding-left:10px;

}

.email_temp_right
{

}
.email_temp_right a
{
     margin-top:3px;
}
 .email_temp_right input {
     float:right;
     font-size: 14px;
     color: #656565;
     font-weight: bold;
     font-family:Segoe UI;
     text-align:left;
     font-weight:normal;
     margin-right:20px;
     border:solid 1px #E3E3E3;
     width:25%;
     height:30px;
     margin-top:5px;
     padding:5px;
}
 .email_temp_right svg {
     color: #acacac !important;
     fill: #acacac !important;
     width: 16px;
     height: 16px;
     margin-top: 4px;
}
 .email_temp_right button {
     margin-bottom:0px;
}
 .custom_field_tab td {
     padding-left:10px !important;
}
 .color_form_dtl_td th {
     background:#e3e3e3;
     color:#7b7b7b;
}
 .label_attchment_form {
     font-size: 18px;
     color: #656565;
     font-weight: bold;
     font-family:Segoe UI;
     text-align:left;
     font-weight:600;
     border-bottom:solid 1px #E3E3E3;
     padding-bottom:2px;
     margin-top:20px;
     padding-left: 0;
}
 .form_detail_iput {
     padding: 5px 8px;
     width: 100%;
     border: none;
     box-shadow: none;
     background-color: transparent;
     background-image: none;
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     padding-left: 20px;
     color: #656565;
     font-size: 16px;
     border: 1px solid #ccc;
     width: 100%;
     border-radius: 0px;
     margin-bottom:20px;
}

.form_temp_detail
{
	       font-size: 30px;
     color: #29429D;
     font-weight: bold;
     font-family:Segoe UI;
     text-align:left;
     font-weight:600;
}
 .form_temp_detail:hover {
     color: #29429D;
}
 .label_dataentry_form_temp{
     margin-bottom: 20px;
}
 .report_input input {
     margin-bottom:20px;
}
 .report_input .select-style {
     margin-bottom:20px;
}
 .btn_general_report {
     background: #4358A7;
     border-radius: 5px !important;
     text-align: center;
     height: 35px !important;
     line-height: 22px;
     font-size: 16px;
     color: #fff;
     text-decoration: none;
     border:solid 1px #4358A7;
     float:left;
     font-weight:600;
     padding-left:30px;
     padding-right:30px;
}
 .btn_general_report:hover {
     background: #fff;
     color: #4358A7;
     text-decoration: none;
     border:solid 1px #4358A7;
}
 .display_none_sm_menu {
     display:none;
}
 #wrapper.toggled #sidebar-wrapper {
     border-right: solid 1px #E4E4E4;
}
 .menu_toggle_mrg {
     margin-top:8px;
}
 .menu_toggle_mrg path:hover {
     color: #03599A;
     fill: #03599A !important;
}
 .menu_toggle_mrg svg:hover {
     color: #03599A;
     fill: #03599A !important;
}
 .adres_map_label {
     display:none;
}
 .modal-backdrop {
     height:100vh !important;
     position:fixed;
}
 .timecard2_popup {
     padding:30px;
     padding-left:30px;
     padding-right:30px;
     padding-top:5px;
}
 a {
     outline:none !important;
}
 .clear5 {
     clear:both;
     height:5px;
}
 .mrg_dashboard_right {
     margin-top: 90px;
     transition:0.7s;
}
 .recent_label {
     color: #707070;
     font-size: 18px;
     font-weight: normal;
}
 .recent_label .date {
     color: #989898;
     font-size: 18px;
     font-weight: normal;
}
 .recent_label .text {
     color: #707070;
     font-size: 18px;
     font-weight: normal;
}
 .recent_h2 {
     color: #707070;
     font-size: 20px;
     font-weight: bold;
}
 .activity-feed {
     padding: 15px;
     padding-left: 20px;
}
 .activity-feed .feed-item {
     position: relative;
     padding-bottom: 20px;
     padding-left: 30px;
     border-left: 2px solid #e4e8eb;
}
 .activity-feed .feed-item:last-child {
     border-color: transparent;
}
 .activity-feed .feed-item:after {
     content: "";
     display: block;
     position: absolute;
     top: 0;
     left: -13px;
     width: 25px;
     height: 25px;
     border-radius: 50%;
     background: #fff;
     border: 1px solid #E4E4E4;
}
 .activity-feed .feed-item .date {
     position: relative;
     top: -5px;
     color: #8c96a3;
     text-transform: uppercase;
     font-size: 13px;
}
 .activity-feed .feed-item .text {
     position: relative;
     top: -3px;
}
 .news_feed_arrow1 {
     position: absolute;
     top: 46%;
     z-index: 8;
     left: 0;
}
 .news_feed_arrow2 {
     position: absolute;
     top: 46%;
     z-index: 8;
     right: 0;
}
 .col-item {
     border: 1px solid #E4E4E4;
     border-radius: 0px;
     background: #FFF;
}
 .col-item .photo img {
     margin: 0 auto;
     width: 100%;
}
 .col-item .info {
     padding: 10px;
     border-radius: 0 0 5px 5px;
     margin-top: 1px;
}
 .col-item:hover .info {
     background-color: #F5F5DC;
}
 .col-item .price {
    /*width: 50%;
    */
     float: left;
     margin-top: 5px;
}
 .col-item .price h5 {
     line-height: 20px;
     margin: 0;
}
 .price-text-color {
     color: #219FD1;
}
 .col-item .info .rating {
     color: #777;
}
 .col-item .rating {
    /*width: 50%;
    */
     float: left;
     font-size: 17px;
     text-align: right;
     line-height: 52px;
     margin-bottom: 10px;
     height: 52px;
}
 .col-item .separator {
     border-top: 1px solid #E1E1E1;
}
 .clear-left {
     clear: left;
}
 .col-item .separator p {
     line-height: 20px;
     margin-bottom: 0;
     margin-top: 10px;
     text-align: center;
}
 .col-item .separator p i {
     margin-right: 5px;
}
 .col-item .btn-add {
     width: 50%;
     float: left;
}
 .col-item .btn-add {
     border-right: 1px solid #E1E1E1;
}
 .col-item .btn-details {
     width: 50%;
     float: left;
     padding-left: 10px;
}
 .controls {
     margin-top: 0px;
}
 [data-slide="prev"] {
     margin-right: 10px;
}
 .news_feed_carsoul h2 {
     font-size: 18px;
     color: #707070;
     font-weight: normal;
     padding: 10px;
     margin: 0px;
     padding-top: 0px;
     padding-bottom: 0px;
     padding-top: 10px;
}
 .news_feed_carsoul p {
     font-size: 16px;
     color: #989898;
     font-weight: normal;
     padding: 10px;
}
 .news_heading_bg {
     font-size: 20px;
     color: #5A5A5A;
     font-weight: bold;
     font-family:Segoe UI;
     text-align:center;
     width:100%;
}
 .recent_activity {
     font-size: 20px;
     color: #5A5A5A;
     font-weight: bold;
     font-family:Segoe UI;
     text-align:left;
     width:100%;
     padding-bottom:10px;
     margin-bottom:10px;
     border-bottom:solid 1px #E6E6E6;
     padding-left:15px;
}
 .news_feedbox2 {
     background: #fff;
     font-size: 14px;
     color: #707070;
     font-weight: bold;
     padding-left: 10px;
     width: 100%;
     border-radius: 5px;
     border: solid 1px #E4E4E4;
     padding: 10px;
     min-height:363px;
}
 .img_wuesu_w {
     height:290px;
}
 .news_feedbox {
     background: #fff;
     font-size: 14px;
     color: #707070;
     font-weight: bold;
     padding-left: 10px;
     width: 100%;
     border-radius: 5px;
     border: solid 1px #E4E4E4;
     padding: 10px;
}
 .news_feedbox_box {
     padding-right:15px !important;
}
 .revent_mrg_bot {
     margin-bottom:15px;
}
 .img_recent_act {
     float:left;
     width:55px;
     height:55px;
     margin-right:10px;
}
 .img_recent_act img {
     width:55px;
     height:55px;
     border-radius:50%;
}
 .recent_act_mid {
     float:left;
     width:60%;
     font-size:14px;
     color:#95989A;
     font-family:Segoe UI;
     font-weight:normal;
}
 .recent_act_mid h2 {
     font-size:14px;
     color:#474b9f;
     font-family:Segoe UI;
     font-weight:bold;
     width:100%;
     margin:0px !important;
     padding:0px !important;
}
 .recent_act_right {
     width:25%;
     font-size:11px;
     color:#95989A;
     font-family:Segoe UI;
     font-weight:bold;
     float:right;
     text-align:right;
     padding-right:20px;
}
 .timecard_box {
     background: #058FCB;
     border-radius: 5px;
     text-align: center;
     font-size: 20px;
     color: #fff;
     font-family:Segoe UI Semibold;
     padding-top: 36px;
     padding-bottom: 36px;
}
 .timecard_box a {
     color: #fff;
}
 .timecard_box svg {
     color: #fff;
}
 .label_weclome_admin {
     font-size: 19px;
     color: #86878A;
     margin-top: 35px;
}
 .label_weclome_admin h2 {
     font-size: 30px;
     color: #86878A;
     font-weight: bold;
     margin: 0px;
     padding: 0px;
}
 .dahboard_hdg {
     font-size: 30px;
     color: #707070;
     font-weight: bold;
     margin-top: 20px;
}
 .welcome_box {
     border: solid 1px #E4E4E4;
     background: #fff;
     padding: 10px;
     border-radius: 5px;
     width: 100%;
}
 .menu_toggle_mrg {
     margin-right: 15px;
}
 .navi_hdg {
     color: #86878A;
     font-size: 18px;
     font-weight: bold;
}
 .dashboard_main_shadow {
     box-shadow:none !Important;
     position: fixed;
     z-index: 9;
     background: #fff;
     min-height: 90px;
     border-bottom:solid 1px #f9f9f9;
}
/* Toggle Styles */
 #wrapper {
     padding-left: 0;
     -webkit-transition: all 0.6s ease;
     -moz-transition: all 0.6s ease;
     -o-transition: all 0.6s ease;
     transition: all 0.6s ease;
}
 #wrapper.toggled {
     padding-left: 200px;
}
 #sidebar-wrapper {
     z-index: 8;
     position: fixed;
     left: 240px;
     width: 0;
     height: 100%;
     margin-left: -250px;
     overflow-y: auto;
     background-color: #fff !Important;
     -webkit-transition: all 0.5s ease;
     -moz-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     transition: all 0.5s ease;
     border: solid 1px #E3E3E3;
     border-right:none;
     margin-top: 87px;
     -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
     -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
     -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
     transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
     width:326px;
}
 #wrapper.toggled #sidebar-wrapper {
     width: 0;
}
 #page-content-wrapper {
     width: 100%;
     position: absolute;
     padding: 10px;
}
 #wrapper.toggled #page-content-wrapper {
     position: absolute;
     margin-left: -250px;
}
/* Sidebar Styles */
 .sidebar-nav {
     position: absolute;
     top: 0;
     right: 0px;
     width: 100%;
     margin: 0;
     padding: 0;
     list-style: none;
}
 .sidebar-nav li {
     text-indent: 20px;
     line-height: 38px;
     border-bottom: solid 1px #E3E3E3;
}
 .sidebar-nav li a {
     display: block;
     text-decoration: none;
     color: #686868;
     font-size: 15px;
     font-weight:600;
}
 .sidebar-nav li a svg {
     width: 16px;
}
 .sidebar-nav li a:hover {
     text-decoration: none;
     color: #03599A;
}
 .sidebar-nav li .cls-1 {
     fill: #949494;
}
 .sidebar-nav li a svg:hover {
     color: #03599A ;
     fill: #03599A !important;
}
 .sidebar-nav li a:active, .sidebar-nav li a:focus {
     text-decoration: none;
     color: #03599A;
     fill: #03599A;
}
.sidebar_active {
     text-decoration: none;
     color: #fff;
     fill: #fff;
     background-color:#03599A;
}
.sidebar .active, .sidebar .active:hover, .sidebar .active:focus {
     color: #fff;
     background-color:#03599A;
     fill: #fff !important;
}
.sidebar_active svg {
     color: #fff;
     fill: #fff !important;
}
 .sidebar_active span {
     color: #fff;
     fill: #fff;
     background-color:#03599A;
}
 .sidebar-nav li a span {
}
 .sidebar_active path {
     color: #fff;
     fill: #fff !important;
     background-color:#03599A;
}
 .sidebar-nav li a:hover svg path, .sidebar-nav li a:hover {
     color: #fff;
     fill: #fff;
     background-color:#03599A;
     height:38px;
}
 .sidebar-nav > .sidebar-brand {
     color: #03599a;
}
 .sidebar-nav > .sidebar-brand a {
     color: #999999;
}
 .sidebar-nav > .sidebar-brand a:hover {
     color: #03599A;
     background: none;
}
 @media(min-width:768px) {
     #wrapper {
         padding-left: 220px;
    }
     #wrapper.toggled {
         padding-left: 0;
    }
     #sidebar-wrapper {
         width: 158px;
    }
     #wrapper.toggled #sidebar-wrapper {
         width: 80px;
         border-right: solid 1px #E4E4E4;
    }
     #wrapper.toggled span {
        /*visibility: hidden;
         display: none;
        */
    }
     #wrapper.toggled svg {
         float: right;
         margin-right: 27px;
         padding-top: 8px;
         font-size:34px !important;
    }
     #wrapper.toggled ul li {
         border-bottom: solid 1px #E3E3E3;
         height: 38px;
    }
     #wrapper.toggled .menu_toggle_mrg {
         margin-right: 13px;
         margin-top: 11px !important;
         height:24px;
         width: 50%;
    }
     #wrapper.toggled .menu_toggle_mrg img {
         float:left;
    }
     #page-content-wrapper {
         padding: 20px;
         position: relative;
    }
     #wrapper.toggled #page-content-wrapper {
         position: relative;
         margin-right: 0;
         margin-right:15px;
    }
}
 @media(max-width:414px) {
     #wrapper.toggled #page-content-wrapper {
         position: absolute;
         margin-right: 60px;
    }
     #wrapper.toggled {
         padding-right: 60px;
    }
     #wrapper {
         padding-left: 20px;
    }
     #wrapper.toggled {
         padding-left: 0;
    }
     #wrapper.toggled #sidebar-wrapper {
         width: 140px;
    }
     #wrapper.toggled span {
         visibility: visible;
         position: relative;
         left: 70px;
         bottom: 13px;
    }
     #wrapper span {
         visibility: hidden;
    }
     #wrapper.toggled svg {
         float: right;
         font-size:38px !important;
    }
     #wrapper i {
         float: right;
    }
     #page-content-wrapper {
         padding: 5px;
         position: relative;
    }
     #wrapper.toggled #page-content-wrapper {
         position: relative;
         margin-right: 0;
    }
}
 .full_display_none {
     display:none;
}
 #responsive_menu {
     display:none;
}
 .full_hidden_slider_feed {
     display:none;
}
 .modla_edit_profile {
     width:849px;
     margin-top:100px;
}
 .user_edting_hd {
     border-bottom:solid 2px #E6E6E6;
     color:#656565;
     font-size:23px;
     text-align:left;
     font-family:Segoe UI Semibold;
     padding-bottom:5px;
}
 .user_edting_hd svg {
     float:left;
     width:17px;
     height:17px;
     color:#707070;
     fill:#707070;
     margin-top:10px;
     margin-right:15px;
     margin-left:10px;
}
 .user_setting_box {
     border: solid 1px #E4E4E4;
     background: #fff;
     padding: 10px;
     border-radius: 5px;
     width: 100%;
     padding-top:40px;
     padding-bottom:40px;
     padding-left:45px;
     padding-right:45px;
}
 .btn_changephoto {
     background: #fff;
     border-radius: 5px !important;
     text-align: center;
     width: 100% !important;
     height: 34px !important;
     line-height: 17px;
     font-size: 15px;
     color: #03599A;
     display: block;
     text-decoration: none;
     border: solid 1px #03599A;
     padding-top: 2px;
}
 .btn_changephoto svg {
     color: #03599A;
     width:18px;
     fill: #03599A;
     margin-right:10px;
     margin-top: 5px;
}
 .btn_changephoto span {
     height: 34px;
     line-height: 31px;
}
 .btn_changephoto:hover {
     background: #fff;
     border: solid 1px #87B0CE;
     color: #03599A;
     text-decoration: none;
}
 .btn_save_pro {
     background: #4358A7;
     border-radius: 5px !important;
     text-align: center;
     width: 97px !important;
     height: 31px !important;
     line-height: 22px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
	border:solid 1px #4358A7;
}
 .btn_save_pro:hover {
     background: #fff;
            color: #4358A7;
     text-decoration: none;
			border:solid 1px #4358A7;
}
 .btn_fetch2 {
     background: #357AAE;
     border-radius: 5px !important;
     text-align: center;
     width: 89px !important;
     height: 26px !important;
     line-height: 22px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border:solid 1px #357AAE;
}
 .btn_fetch2:hover {
     background: #fff;
     color: #357AAE;
     text-decoration: none;
     border:solid 1px #357AAE;
}
 .btn_save_pro {
     background: #4358A7;
     border-radius: 5px !important;
     text-align: center;
     width: 97px !important;
     height: 31px !important;
     line-height: 22px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border:solid 1px #357AAE;
     float:left;
     font-weight:600;
}
 .btn_save_pro:hover {
     background: #fff;
     color: #357AAE;
     text-decoration: none;
     border:solid 1px #357AAE;
}
 .btn_cancel_pro {
     background: #C4C4C4;
     border-radius: 5px !important;
     text-align: center;
     width: 97px !important;
     height: 31px !important;
     line-height: 26px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border:solid 1px #C4C4C4;
     float:left;
     margin-left:5px;
}
 .btn_cancel_pro:hover {
     background: #fff;
     color: #357AAE;
     text-decoration: none;
     border:solid 1px #C4C4C4;
}
 .btn_change_pass_pro {
     background: #fff;
     border-radius: 5px !important;
     text-align: center;
     width: 163px !important;
     height: 34px !important;
     line-height: 29px;
     font-size: 15px;
     color: #03599A;
     display: block;
     text-decoration: none;
     border: solid 1px #E6E6E6;
     padding-top: 2px;
}
 .btn_change_pass_pro svg {
     color: #03599A;
     width: 14px;
     fill: #03599A;
     margin-top: 3px !important;
     margin-right: 10px;
     margin-top:3px;
     height: 15px;
     width: 15px;
}
 .btn_change_pass_pro span {
     height: 34px;
     line-height: 25px;
}
 .btn_change_pass_pro:hover {
     background: #fff;
     border: solid 1px #87B0CE;
     color: #03599A;
     text-decoration: none;
}
 .btn_copy_time{
     background: #fff;
     border-radius: 5px !important;
     text-align: center;
     width: 200px !important;
     height: 34px !important;
     line-height: 29px;
     font-size: 15px;
     color: #03599A;
     display: block;
     text-decoration: none;
     border: solid 1px #E6E6E6;
     padding-top: 2px;
}
 .btn_copy_time:hover {
     background: #fff;
     border: solid 1px #87B0CE;
     color: #03599A;
     text-decoration: none;
}
 .profile_setting_pop .control-label {
     color:#707070;
     font-size:17px;
     font-weight:normal;
     text-align:left;
     padding-left:30px;
}
 .profile_setting_pop .form-group {
     margin-bottom:10px !important;
}
 .pro_input_pop {
     border:solid 1px #E6E6E6;
     color:#707070;
     font-size:15px;
     border-radius:0px;
     box-shadow:none;
}
 .pro_input_pop::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
     color:#707070;
     font-size:15px;
}
 .pro_input_pop::-moz-placeholder {
    /* Firefox 19+ */
     color:#707070;
     font-size:15px;
}
 a:hover, a:focus {
     color: inherit;
     text-decoration: none;
}
 .timecard_dele svg {
     color:#acacac;
     width:14px;
     height:19px;
     margin-top:2px;
}
 .timecard_edit6 svg {
     color:#acacac !important;
     fill:#acacac !important;
     width:18px;
     height:18px;
     margin-top:2px;
}
 .timecard_edit6 a {
     float:right;
     margin-right:10px;
}
 .timecard_edit6 path {
     color:#acacac !important;
     fill:#acacac !important;
}
 .timecard_edit6 path:hover {
     color:#03599A !important;
     fill: #03599A !important
}
 .timecard_dele path {
     color:#acacac !important;
     fill: #acacac;
}
 .timecard_dele path:hover {
     color:#03599A !important;
     fill: #03599A !important
}
 .heading_1 {
     font-size:20px;
     color:#656565;
     font-weight:bold;
     border-bottom:solid 1px #E3E3E3;
     padding-bottom:5px;
}
 .time_table_mrg_res {
     display:none;
}
 .calendar_time2 {
     margin-top:4px;
}
 .calendar_time2 svg path {
     color:#357AAE;
     width:18px;
     height:23px;
     fill: #357AAE;
     margin-top:5px;
}
 .calendar_time2 a {
     margin-top:5px;
}
 .timecard2_table th {
     background:#EEEEEE;
     color:#7B7B7B;
     text-align:center !important;
     font-size:14px;
}
 .label_timecard2 {
     color:#02477C;
     font-size:18px;
}
 .btn_price_time2 {
     background: #fff;
     border-radius: 5px !important;
     text-align: center;
     height: 30px !important;
     line-height: 25px;
     font-size: 15px;
     color: #03599A;
     display: block;
     text-decoration: none;
     border: solid 1px #E6E6E6;
     padding-top: 2px;
     margin-left:10px;
}
 .attchment_bottom_label {
     color:#656565;
}
 .drag_drop_box {
     border:solid 1px #E3E3E3;
     min-height:121px;
     text-align:center;
     color:#A5A5A5;
     font-size:20px;
}
 .drag_drop_box svg {
     width:52px;
     height:34px;
     color:#A5A5A5;
     margin-top:20px;
     fill:#ACACAC;
}
 .btn_prview_time {
     background: #65C3EC;
     border-radius: 5px !important;
     text-align: center;
     width: 97px !important;
     height: 31px !important;
     line-height: 26px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border:solid 1px #65C3EC;
     float:right;
}
 .btn_prview_time:hover {
     background: #fff;
     color: #65C3EC;
     text-decoration: none;
     border:solid 1px #65C3EC;
}
 .btn_submit_time {
     background: #70D88F;
     border-radius: 5px !important;
     text-align: center;
     width: 97px !important;
     height: 31px !important;
     line-height: 26px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border:solid 1px #70D88F;
     float:right;
     margin-left:10px;
}
 .btn_submit_time:hover {
     background: #fff;
     color: #70D88F;
     text-decoration: none;
     border:solid 1px #70D88F;
}
 .heading_time2_digi {
     font-size:20px;
     color:#03599A;
     font-weight:bold;
     border-bottom:solid 1px #E3E3E3;
     padding-bottom:5px;
     display:block;
}
 .timecard_2popup {
     width: 40%;
     margin-top: 50px;
}
 .time_card_popup3 {
     margin-top:0px;
}
 .time_card_popup3 svg {
     color:#357AAE;
     width:18px !important;
     height:23px !important;
     fill: #357AAE;
     margin-top:5px;
     float:left;
}
 .btn_copytime {
     background: #fff;
     border-radius: 5px !important;
     text-align: center;
     width: 200px !important;
     height: 34px !important;
     line-height: 29px;
     font-size: 15px;
     color: #03599A;
     display: block;
     text-decoration: none;
     border: solid 1px #E6E6E6;
     padding-top: 2px;
}
 .box_pop_time5 {
     border:solid 1px #E4E4E4;
     padding:20px;
     margin-bottom:10px;
}
 .checkbox_popuptime {
     margin-top:8px;
}
 .res_table_time_svg5 svg {
     color:#357AAE;
     fill:#357AAE;
     width:7px;
     height:12px;
}
 .modla_error_timecard {
     width:40%;
     margin-top:100px;
     font-size:18px;
     color:#95989A;
}
 .modla_error_timecard svg {
     width:90px;
     height:77px;
     fill: #ffda44;
}
 .btn_delete_error {
     background: #F84F5F;
     border-radius: 5px !important;
     text-align: center;
     width: 97px !important;
     height: 31px !important;
     line-height: 26px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border:solid 1px #F84F5F;
     margin-left:30px;
     float:left;
}
 .btn_delete_error:hover {
     background: #fff;
     color: #F84F5F;
     text-decoration: none;
     border:solid 1px #F84F5F;
}
 .submit_timesheet_box {
     border:solid 1px #E3E3E3;
     min-height:121px;
     text-align:center;
     color:#A5A5A5;
     font-size:14px;
     margin-top:50px;
     padding:50px;
}
 .submit_timesheet_box svg {
     width:100px;
     height:60px;
     color:#03599A;
     margin-top:20px;
     fill:#03599A;
}
 .heading_submit_timeshhet {
     font-size:30px;
     font-weight:bold;
     color:#656565;
}
 .blue_color {
     color:#03599A;
     text-align:left;
}
 .modla_submit_timesheet {
     width: 40%;
}
 .company_search input {
     padding-left:35px;
}
 .company_search svg {
     position:absolute;
     color:#949494;
     fill:#949494;
     top:10px;
     left:30px;
     width:18px;
     height:18px;
}
 .mrg_top0 {
     margin-top:0px !important;
     color: #707070;
     font-size: 18px;
     font-weight: normal;
     text-align: left;
}
 .privacy_policy {
     font-size: 16px;
     color: #989898;
     font-weight: normal;
}
 .privacy_policy h2 {
     font-size: 24px;
     font-weight:bold;
     color:#000;
}
 .privacy_policy strong {
     font-size: 18px;
     font-weight:bold;
     color:#000;
}
 .document_left th {
     text-align:left !important;
}
 .general_settig_box {
     border:solid 1px #E4E4E4;
     padding:5px;
     border-radius:5px;
     height:46px;
     background:#fff;
}
 .general_settig_box_label {
     color:#707070;
     margin-top:5px;
     margin-left:10px;
     font-size: 18px;
     font-weight: normal;
}
 .button_clear {
     background:#fff;
     border:solid 2px #3FB3E4;
     color:#3FB3E4;
     font-size:18px;
     width:88px;
     height:34px;
     text-align:center;
     border-radius:5px;
}
 .bg_clor_setting {
     background:#F8F8F8;
}
/* {
     background:#F4F7F7;
     padding:30px;
     text-align:center;
     font-size:46px;
     color:#03599A;
     font-weight:bolder;
     width: 123.4%;
     margin-left: -22%;
     margin-top:-3px;
     padding-left:23.4%;
}
 */
 .help_body_box {
     border-radius: 0px;
     margin: auto;
     margin-top: 0px;
     border: solid 1px #E4E4E4;
     margin-bottom:30px;
}
 .help_panel_group {
     border: none;
     box-shadow: none !important;
}
 .help_panel_heading {
     color: #4358A7 !important;
     background-color: transparent !important;
     border-color: none !important;
     border-bottom: solid 1px #e6e6e6 !important;
     font-size:20px !important;
     font-weight:bold !important;
}
 .help_panel_heading .panel-title {
     font-size:20px !important;
     font-family: Segoe UI !important;
     font-weight:600;
}
 .help_carsoul_img img {
     width:120px !important;
     height:133px !important;
     margin:auto;
     margin-top:50px;
}
 .help_carsoulcaption {
     text-align:center;
     color:#949494;
     font-size:18px;
     position: inherit !important;
     margin-bottom: auto !important;
     margin-left: auto !important;
     bottom: 20px;
     left: auto !important;
     right: auto !important;
}
 .help_carsoulcaption h3 {
     text-align:center;
     color:#357AAE;
     font-size:30px;
     font-weight:bold;
     text-shadow:none !important;
}
 .help_carsoulcaption p {
     text-align:center;
     color:#949494;
     font-size:18px;
     margin-bottom:50px;
     text-shadow:none !important;
}
 .carousel-control img {
     bottom: 0;
     position: absolute;
     vertical-align: middle;
     top: 50%;
}
 .help_input_contact {
     border:solid 1px #E6E6E6;
     color:#707070;
     font-size:15px;
     border-radius:5px;
     box-shadow:none;
     padding-left:40px;
     height:43px;
     background:#fff;
}
 .help_img_contact svg {
     position: absolute;
     top: 10px;
     left: 28px;
     color: #ccc;
     fill: #ccc;
     width:16px;
     height:16px;
}
 .mrg_left_con {
     margin-left:53px;
}
 .mrg_left_sign {
     margin-left: 18px;
}
 .btn_send_msg_help {
     background: #357AAE;
     border-radius: 5px !important;
     text-align: center;
     width: 180px;
     height: 40px !important;
     line-height: 26px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border:solid 1px #357AAE;
     margin:auto;
}
 .btn_send_msg_help:hover {
     background: #fff;
     color: #357AAE;
     text-decoration: none;
     border:solid 1px #357AAE;
}
 .post_adrs_label {
     color:#02477C;
     font-weight:bold;
     font-size:18px;
}
 .tab_label_help table td {
     font-size:17px;
     color:#858789;
}
 .table-bordered > tbody > tr > td {
     border:none !important;
     border-bottom: solid 1px #e4e4e4 !important;
}
 .table-bordered > thead > tr > th {
     border:none !important;
}
 .plus_icon_table {
     font-size: 32px;
     padding: 7px !important;
     line-height: 20px;
     height: auto !important;
     margin: 0;
     padding-bottom: 8px !important;
     padding-top: 1px !important;
     margin-bottom:10px;
}
 .btn_save_pro_pop {
     background: #357AAE;
     border-radius: 5px !important;
     text-align: center;
     width: 75px !important;
     height: 31px !important;
     line-height: 26px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border: solid 1px #357AAE;
     float: left;
}
 .btn_cancel_pro_pop {
     background: #C4C4C4;
     border-radius: 5px !important;
     text-align: center;
     width: 75px !important;
     height: 31px !important;
     line-height: 26px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border: solid 1px #C4C4C4;
     float: left;
     margin-left: 5px;
}
 .activity_feed_sm {
     border-left:solid 1px #E4E4E4;
     border-top:solid 1px #E4E4E4;
     padding-left:10px;
}
 .recent_label {
     margin-top: -68px;
}
 #sidebar-wrapper {
     border-right:solid 1px #E4E4E4;
}
 .center_btn_pop3 {
     margin: auto;
     width: 247px;
}
 .contact_bg {
     background:#fff;
     padding:10px;
     padding-left:80px;
     padding-right:80px;
     height: 667px !important;
}
 .sep_get_in {
     background:#E4E4E4;
     width:1px;
     height:100%;
     min-height: 645px;
     margin:auto;
}
 .hd_get_gelp_getin {
     font-size: 45px;
     color: #03599A;
     font-weight: bold;
     width: 100%;
     text-align: center;
     margin-top: 20px;
}
 .getin_box_mtg {
     margin-top:140px !important;
}
 .svg_sign5 {
     font-size: 22px;
     color: #656565;
     font-family: Segoe UI Semibold;
     padding-bottom: 5px;
}

.svg_sign5 svg
{
	color:#656565; fill:#656565;
     width:20px;
     height:20px;
     float:left;
}
 .svg_sign5 span {
     float:left;
     font-size: 22px;
     color: #656565;
     font-family: Segoe UI Semibold;
     padding-bottom: 5px;
     line-height: 15px;
     margin-left: 10px;
}
 .arrow_getintoiuch {
     position: absolute;
     left: 10px;
     top: 17px;
}
 .dashboard_main_shadow img {
     width: 200px !important;
     height: 75px;
     margin-top: 9px;
     margin-left: 10px;
}
 .dahsboard_gray_box_label {
     font-size:16px;
}
 .dashboard_welcomebox {
     background: #F5F5F5;
     padding: 20px;
     border-radius: 5px;
}
 .profile_img {
     border-radius:50%;
     width:83px;
     height:83px;
}
 .btn_edit_profile {
     margin:auto;
     background:#29429d;
     padding:3px;
     border-radius:5px;
     padding-left:20px;
     padding-right:20px;
     color:#fff;
     border:solid 1px #29429d;
     font-family:Source Sans Pro !important;
     font-weight:normal !important;
     font-size:18px;
}
 .btn_edit_profile:hover {
     background:#fff;
     color:#29429d;
     border:solid 1px #29429d;
}
 .dahsboard_gray_box_label {
     color:#686868;
     font-family:Source Sans Pro !important;
     font-weight:normal !important;
     font-size:14px;
     line-height:25px;
}
 .dahsboard_gray_box_label h2 {
     color:#5A5A5A;
     font-family:Source Sans Pro !important;
     font-weight:600;
     font-size:20px;
     margin-top:10px;
}
 .btn_document_Dash {
     margin:auto;
     background:#29429d;
     padding:5px;
     border-radius:5px;
     padding-left:30px;
     padding-right:30px;
     color:#fff;
     border:solid 1px #29429d;
     font-family:Segoe UI !important;
     font-weight:600;
     font-size:18px;
}
 .btn_document_Dash svg {
     width:14px;
     height:14px;
     fill:#fff;
}
 .btn_document_Dash:hover {
     background:#fff;
     color:#29429d;
     border:solid 1px #29429d;
}
 .btn_document_Dash:hover svg {
     fill: #29429d;
     color:#29429d;
     width:14px;
     height:14px;
}
 .draft_label_dash {
     text-align:center;
     font-family:Segoe UI !important;
     font-weight:600;
     font-size:14px;
     color:#29419D;
}
 .draft_label_dash h3 {
     text-align:center;
     font-family:Source Sans Pro !important;
     font-weight:600;
     font-size:35px;
     color:#5467b1;
     font-weight:bold;
     margin-top:40px;
}
 .aproved_label_dash {
     text-align:center;
     font-family:Segoe UI !important;
     font-weight:600;
     font-size:14px;
     color:#66a9bc;
     padding-left:5px !important;
     padding-right:5px !important;
}
 .aproved_label_dash h3 {
     text-align:center;
     font-family:Source Sans Pro !important;
     font-weight:600;
     font-size:35px;
     color:#66a9bc;
     font-weight:bold;
     margin-top:40px;
}
 .pending_label_dash {
     text-align:center;
     font-family:Segoe UI !important;
     font-weight:600;
     font-size:14px;
     color:#FFC883;
     padding-left:5px !important;
     padding-right:5px !important;
}
 .pending_label_dash h3 {
     text-align:center;
     font-family:Source Sans Pro !important;
     font-size:35px;
     color:#ffd39c;
     font-weight:bold;
     margin-top:40px;
}
 .declined_label_dash {
     text-align:center;
     font-family:Segoe UI !important;
     font-weight:600;
     font-size:14px;
     color:#ff5b5b;
     padding-left:5px !important;
     padding-right:5px !important;
}
 .declined_label_dash h3 {
     text-align:center;
     font-family:Source Sans Pro !important;
     font-size:35px;
     color:#ff7c7c;
     font-weight:bold;
     margin-top:40px;
}
 .amproved_label_dash {
     text-align:center;
     font-family:Segoe UI !important;
     font-weight:600;
     font-size:14px;
     color:#46C56C;
     padding-left:5px !important;
     padding-right:5px !important;
}
 .amproved_label_dash h3 {
     text-align:center;
     font-family:Source Sans Pro !important;
     font-weight:600;
     font-size:35px;
     color:#6bd189;
     font-weight:bold;
     margin-top:40px;
}
 .all_label_dash {
     text-align:center;
     font-family:Segoe UI !important;
     font-size:16px;
     color:#989898;
     padding-left:5px !important;
     padding-right:5px !important;
}
 .all_label_dash h3 {
     text-align:center;
     font-family:Source Sans Pro !important;
     font-size:35px;
     color:#adadad;
     font-weight:bold;
     margin-top:40px;
}
 .modal_customefield {
     font-size:25px;
     color:#86878A;
     font-family:Segoe UI !important;
     font-weight:600;
}
 .label_modal_custom {
     font-size:18px;
     color:#656565;
     font-family:Segoe UI !important;
     font-weight:normal;
}
 .label_modal_custom input {
     border:solid 1px #E6E6E6;
     color:#656565;
     font-size:15px;
     border-radius:0px;
     box-shadow:none;
     padding-left:10px;
     height:33px;
     background:#fff;
     width:100%;
     outline:none;
}
 .label_modal_custom select {
     border:solid 1px #E6E6E6;
     color:#656565;
     font-size:15px;
     border-radius:0px;
     box-shadow:none;
     padding-left:10px;
     height:33px;
     background:#fff;
     width:100%;
     outline:none;
}
 .mrg_bot_custom10 {
     margin-bottom:20px;
}
 .label_csutom_pop {
     padding-top:5px;
}
 .btn_save_pro {
     background: #4358A7;
     border-radius: 5px !important;
     text-align: center;
     width: 97px !important;
     height: 31px !important;
     line-height: 22px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border:solid 1px #357AAE;
     float:left;
     font-weight:600;
}
 .btn_save_pro:hover {
     background: #fff;
            color: #4358A7;
     text-decoration: none;
			border:solid 1px #4358A7;
}
 .btn_cancel_pro {
     background: #C4C4C4;
     border-radius: 5px !important;
     text-align: center;
     width: 97px !important;
     height: 31px !important;
     line-height: 26px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border:solid 1px #C4C4C4;
     float:left;
     margin-left:5px;
}
 .btn_cancel_pro:hover {
     background: #fff;
            color: #4358A7;
     text-decoration: none;
     border:solid 1px #C4C4C4;
}
 .btn_change_pass_pro {
     background: #fff;
     border-radius: 5px !important;
     text-align: center;
     width: 163px !important;
     height: 34px !important;
     line-height: 29px;
     font-size: 15px;
     color: #03599A;
     display: block;
     text-decoration: none;
     border: solid 1px #E6E6E6;
     padding-top: 2px;
}
 .sidebar-nav {
}
 .checkmark_form_pck_pop {
     height: 15px !important;
     width: 15px !important;
}
 .checkmark_form_pck_pop:after {
     left: 5px !important;
     top: 1px !important;
}
 .company_pop table tr td a {
     color:#7B7B7B !important;
}
 @media (min-width: 1440px) and (max-width: 3500px){
     .company_setting {
          font-size: 18px;
          color: #656565;
          margin-bottom: 20px;
    }
    .company_setting .label {
            margin-top: 5px;
            font-size: 18px;
            color: #656565;
            font-weight: normal;
            text-align: left;
    }
     .company_setting {
         font-size:18px;
         color:#656565;
    }
     .dahsboard_gray_box_label {
         font-size:16px;
    }
     .dashboard_welcomebox {
         background: #F5F5F5;
         padding: 20px;
         border-radius: 5px;
    }
     .profile_img {
         border-radius:50%;
         width:83px;
         height:83px;
    }
     .btn_edit_profile {
         margin:auto;
         background:#29429d;
         padding:3px;
         border-radius:5px;
         padding-left:20px;
         padding-right:20px;
         color:#fff;
         border:solid 1px #29429d;
         font-family:Source Sans Pro !important;
         font-weight:normal !important;
         font-size:18px;
    }
     .btn_edit_profile:hover {
         background:#fff;
         color:#29429d;
         border:solid 1px #29429d;
    }
     .dahsboard_gray_box_label {
         color:#686868;
         font-family:Source Sans Pro !important;
         font-weight:normal !important;
         font-size:14px;
         line-height:25px;
    }
     .dahsboard_gray_box_label h2 {
         color:#5A5A5A;
         font-family:Source Sans Pro !important;
         font-weight:600;
         font-size:20px;
         margin-top:10px;
    }
     .btn_document_Dash {
         margin:auto;
         background:#29429d;
         padding:5px;
         border-radius:5px;
         padding-left:30px;
         padding-right:30px;
         color:#fff;
         border:solid 1px #29429d;
         font-family:Segoe UI !important;
         font-weight:600;
         font-size:18px;
    }
     .btn_document_Dash svg {
         width:14px;
         height:14px;
         fill:#fff;
    }
     .btn_document_Dash:hover {
         background:#fff;
         color:#29429d;
         border:solid 1px #29429d;
    }
     .btn_document_Dash:hover svg {
         fill: #29429d;
         color:#29429d;
         width:14px;
         height:14px;
    }
     .draft_label_dash {
         text-align:center;
         font-family:Segoe UI !important;
         font-weight:600;
         font-size:16px;
         color:#29419D;
    }
     .draft_label_dash h3 {
         text-align:center;
         font-family:Source Sans Pro !important;
         font-weight:600;
         font-size:40px;
         color:#5467b1;
         font-weight:bold;
         margin-top:40px;
    }
     .aproved_label_dash {
         text-align:center;
         font-family:Segoe UI !important;
         font-weight:600;
         font-size:16px;
         color:#66a9bc;
         padding-left:5px !important;
         padding-right:5px !important;
    }
     .aproved_label_dash h3 {
         text-align:center;
         font-family:Source Sans Pro !important;
         font-weight:600;
         font-size:40px;
         color:#66a9bc;
         font-weight:bold;
         margin-top:40px;
    }
     .pending_label_dash {
         text-align:center;
         font-family:Segoe UI !important;
         font-weight:600;
         font-size:16px;
         color:#FFC883;
         padding-left:5px !important;
         padding-right:5px !important;
    }
     .pending_label_dash h3 {
         text-align:center;
         font-family:Source Sans Pro !important;
         font-weight:600;
         font-size:40px;
         color:#ffd39c;
         font-weight:bold;
         margin-top:40px;
    }
     .declined_label_dash {
         text-align:center;
         font-family:Segoe UI !important;
         font-weight:600;
         font-size:16px;
         color:#ff5b5b;
         padding-left:5px !important;
         padding-right:5px !important;
    }
     .declined_label_dash h3 {
         text-align:center;
         font-family:Source Sans Pro !important;
         font-weight:600;
         font-size:40px;
         color:#ff7c7c;
         font-weight:bold;
         margin-top:40px;
    }
    
     .amproved_label_dash h3 {
         text-align:center;
         font-family:Source Sans Pro !important;
         font-weight:600;
         font-size:40px;
         color:#6bd189;
         font-weight:bold;
         margin-top:40px;
    }
     .all_label_dash {
         text-align:center;
         font-family:Segoe UI !important;
         font-weight:600;
         font-size:16px;
         color:#989898;
         padding-left:5px !important;
         padding-right:5px !important;
    }
     .all_label_dash h3 {
         text-align:center;
         font-family:Source Sans Pro !important;
         font-weight:600;
         font-size:40px;
         color:#adadad;
         font-weight:bold;
         margin-top:40px;
    }
     .custome_lsit_field_table th {
         font-weight:600 !important;
         background:#29429d;
         color:#fff;
         text-align:left !important;
         font-size:17px;
         padding-left:7px !important;
         border-bottom:none !important;
    }
     .custome_lsit_field_table td {
         text-align:left !important;
         font-size:15px;
         padding-top: 13px !important;
         padding-bottom: 3px !important;
    }
}
@media (min-width: 1446px) and (max-width: 2560px){
 .amproved_label_dash {
         text-align:center;
         font-family:Segoe UI !important;
         font-weight:600;
         font-size:16px;
         color:#46C56C;
         padding-left:5px !important;
         padding-right:5px !important;
}
}
 @media (min-width: 768px) and (max-width: 1500px){
    /*.overflow_tab_custom_fld {
         overflow-x:scroll !important;
    }
    */
}
 @media (min-width: 1200px) and (max-width: 1439px){
     .company_setting {
         font-size:18px;
         color:#656565;
         margin-bottom:20px;
    }
     .company_setting .label {
         margin-top:5px;
         font-size:18px;
         color:#656565;
         font-weight:normal;
         text-align:left;
    }
     .custome_header_label {
         font-size: 20px;
    }
    /*.overflow_tab_custom_fld {
         overflow-x:scroll;
    }
    */
}
 @media (min-width: 1281px) and (max-width: 2560px){
     .dashboard_main_shadow {
         min-height: 90px;
    }
     #sidebar-wrapper {
         width: 17%;
         margin-top:90px;
    }
}
 @media (min-width: 2561px) and (max-width: 3500px){
	#sidebar-wrapper {
         width: 17%;
         margin-top:90px;
    }
 }
 @media (min-width: 991px) and (max-width: 1340px){
     .amproved_label_dash {
         font-size:11px;
    }
     .amproved_label_dash img {
         width: 12px;
    }
     .draft_label_dash {
         font-size:12px;
    }
     .draft_label_dash img {
         width: 12px;
    }
     .aproved_label_dash {
         font-size:12px;
    }
     .aproved_label_dash img {
         width: 12px;
    }
     .pending_label_dash {
         font-size:12px;
    }
     .pending_label_dash img {
         width: 12px;
    }
     .declined_label_dash {
         font-size:12px;
    }
     .declined_label_dash img {
         width: 12px;
    }
     .all_label_dash {
         font-size:12px;
    }
     .all_label_dash img {
         width: 12px;
    }
     .draft_right_area span {
         font-size:14px !important;
         width: 100%;
         display: block;
    }
}
 @media (min-width: 991px) and (max-width: 1300px){
     .email_temp_hdr label {
         font-size:13px;
    }
     .email_temp_hdr {
         height: 57px;
    }
     .custome_field_table th {
         font-size:12px;
    }
     .recent_act_right {
         font-size: 9px;
         padding-right: 0px;
    }
     .recent_act_mid {
         width: 50%;
         font-size: 12px;
    }
     .btn_edit_profile {
         font-size:10px;
    }
     .dahsboard_gray_box_label {
         font-size: 11px;
         line-height: 18px;
    }
     .dahsboard_gray_box_label h2 {
         font-size: 16px;
         margin-top: 10px;
    }
}
 @media (min-width: 1024px) and (max-width: 1280px){
     .btn_edit_profile {
         font-size:11px;
    }
     #sidebar-wrapper {
         width: 17%;
    }
     .modla_edit_profile {
         width:80%;
         margin-top:100px;
    }
     .navi_hdg {
         margin-left:0px !important;
    }
     .sidebar-nav li a {
         font-size: 15px;
    }
}
 @media (min-width: 992px) and (max-width: 1199px){
     .custome_header_label {
         font-size: 15px;
    }
     .header_top_right a {
         padding-left: 15px;
         margin-left: 15px;
    }
     .sidebar-nav > .sidebar-brand a {
         width: 38px;
         float: right;
    }
     .sidebar-nav li a span {
         font-size: 12px;
    }
     .navi_hdg {
         font-size: 14px;
    }
     .company_setting {
         font-size:14px;
         color:#656565;
         margin-bottom:20px;
    }
     .company_setting .label {
         margin-top:5px;
         font-size:14px;
         color:#656565;
         font-weight:normal;
         text-align:left;
    }
}
 @media (min-width: 768px) and (max-width: 991px){
     .company_setting {
         font-size:14px;
         color:#656565;
         margin-bottom:20px;
    }
     .company_setting .label {
         margin-top:5px;
         font-size:14px;
         color:#656565;
         font-weight:normal;
         text-align:left;
    }
     .email_temp_hdr label {
         font-size:13px;
    }
     .email_temp_hdr {
         height: 57px;
    }
     .email_temp_hdr label {
         margin-right: 8px;
    }
     .sidebar-nav > .sidebar-brand a {
         width: 38px;
         float: right;
    }
     .sidebar-nav li a span {
         font-size: 11px;
    }
     .navi_hdg {
         font-size: 12px;
    }
     .mrg_dashboard_right {
         width: 83%;
    }
     .amproved_label_dash {
         font-size:11px;
    }
     .amproved_label_dash img {
         width: 12px;
    }
     .draft_label_dash {
         font-size:12px;
    }
     .draft_label_dash img {
         width: 12px;
    }
     .aproved_label_dash {
         font-size:12px;
    }
     .aproved_label_dash img {
         width: 12px;
    }
     .pending_label_dash {
         font-size:12px;
    }
     .pending_label_dash img {
         width: 12px;
    }
     .declined_label_dash {
         font-size:12px;
    }
     .declined_label_dash img {
         width: 12px;
    }
     .all_label_dash {
         font-size:12px;
    }
     .all_label_dash img {
         width: 12px;
    }
     .btn_edit_profile {
         font-size:11px;
    }
     .custome_field_table th {
         font-size:11px;
         padding-left:2px;
         white-space: nowrap;
    }
     .table-bordered > tbody > tr > td {
         font-size:11px;
         padding-left:2px;
    }
     .custome_header_label {
         font-size: 14px;
    }
     .header_top_right a {
         padding-left: 5px;
         margin-left: 5px;
    }
     .header_top_right a {
         font-size: 14px !important;
    }
     .custome_header_label {
         font-size: 12px;
         padding-top:40px;
    }
     .recent_act_mid {
         width: 45%;
         font-size: 11px;
    }
     .recent_act_right {
         width: 25%;
    }
}
 @media (min-width: 768px) and (max-width: 1023px){
     .btn_edit_profile {
         font-size:10px;
    }
     .user_setting_box {
         padding-left:35px;
         padding-right:35px;
    }
     .dashboard_main_shadow img {
         width: 200px !important;
         height: 75px;
         margin-top: 9px;
         margin-left: 10px;
    }
     .btn_adduser_pck_pop {
         padding-left: 10px;
         padding-right: 10px;
         font-size: 12px;
    }
}
 @media (min-width: 320px) and (max-width: 767px){
     .modal_w_dashedit {
         width:92%;
    }
     .dash_proifle_edit_pop {
         min-height:auto;
    }
     .add_file_btn {
         margin-top: 20px;
         display: block;
         width: 100%;
    }
     .general_setting_hd select {
         margin-top:5px;
    }
     .company_setting {
         font-size:14px;
         color:#656565;
         margin-bottom:20px;
    }
     .company_setting .label {
         margin-top:5px;
         font-size:14px;
         color:#656565;
         font-weight:normal;
         text-align:left;
         margin-bottom:10px;
         padding-bottom:10px;
         padding-left: 15px;
         clear:both;
    }
     .company_setting input {
         margin-top:5px;
    }
     .table_formpck_pop_1 table th {
         font-size: 12px;
         white-space: nowrap;
    }
     .table_formpck_pop_1 table td {
         font-size: 11px;
    }
     .scroll_form_pcktable {
         overflow-x:scroll;
    }
     .email_temp_hdr {
         min-height: 139px;
         padding-top: 26px;
    }
     .email_temp_hdr label {
         font-size:13px;
    }
     .email_temp_hdr {
         height: 57px;
    }
     .email_temp_right {
         margin-top: 20px;
    }
     .report_input input {
         margin-bottom:0px;
    }
     .report_input .select-style {
         margin-bottom:0px;
    }
     .sidebar-nav {
         overflow-y:scroll;
         height:400px;
    }
     .mrg_dashboard_right {
         margin-top: 130px;
         transition: 0.7s;
    }
     .menu-toggle_mobile {
         position:absolute;
         z-index:99999;
    }
     #sidebar-wrapper {
         margin-top:23%;
         z-index:999;
    }
     .display_none_full_menu {
         display:none;
    }
     .display_none_sm_menu {
         display:block;
    }
     #mobile_menu_sm {
         -webkit-transition: all 0.6s ease;
         -moz-transition: all 0.6s ease;
         -o-transition: all 0.6s ease;
         transition: all 0.6s ease;
    }
     .news_feedbox_box {
         padding-right:0px !important;
         margin-bottom:20px;
    }
     .btn_save_pro {
         width: 87px !important;
    }
     .label_csutom_pop {
         margin-bottom:5px;
    }
     .mrg_bot_custom10 {
         margin-bottom: 10px;
    }
     .plus_icon_table {
         margin-bottom:0px;
    }
     .timecard_dele svg {
         color: #acacac;
         width: 9px;
         height: 16px;
         margin-top: 6px;
    }
     .timecard_edit6 svg {
         color: #acacac !important;
         fill: #acacac !important;
         width: 12px;
         height: 16px;
         margin-top: 2px;
         margin-right: 5px;
    }
     .custome_field_table th {
         font-size:11px;
         padding-left:2px;
         white-space: nowrap;
    }
     .table-bordered > tbody > tr > td {
         font-size:11px;
         padding-left:2px;
    }
     .overflow_scroll_sm {
         overflow-x:scroll;
         margin-top:20px;
    }
     .timecard_edit6 a {
         float: right;
         margin-right: 0px;
         margin-top:5px;
    }
     .dashboard_main_shadow {
         width:100%;
    }
     .dashboard_main_shadow img {
         width: 100px !important;
         height: 38px;
         margin-top: 25px;
         margin-left: 10px;
    }
     .header_top_right {
         padding-top: 20px !important;
         padding-bottom:20px !important;
    }
     .custome_header_label {
         text-align:left;
         padding-top:20px;
    }
     .revent_mrg_bot {
         width: 100%;
         clear: both;
         margin-bottom: 71px;
    }
     .mrg_bot_smc {
         margin-bottom:40px !important;
    }
     .email_hdr_select {
         float: left;
         margin-right: 12px;
    width: 161px;
         margin-top: -5px;
    }
}
 @media (min-width: 320px) and (max-width: 481px){
     .header_top_right {
         padding-top: 30px !important;
         padding-bottom: 20px !important;
    }
     .header_top_right a {
         padding-left: 9px;
         margin-left: 9px;
    }
     .email_temp_hdr {
         min-height: 160px;
    padding-top: 10px;
    }
     .email_hdr_select {
         float: left;
         margin-right: 12px;
    width: 161px;
         margin-top: -5px;
    }
}
 .checkbox-warning-filled [type="checkbox"][class*='filled-in']:checked+label:after {
     border-color: #FF8800;
     background-color: #FF8800;
}
 @media screen and (-webkit-min-device-pixel-ratio:0) {
    /* Safari only override */
     ::i-block-chrome, .dashboard_main_shadow{
        height: auto !important; /* on Safari and Chrome  */
    }
     ::i-block-chrome, .header_top_right {
         padding-top: 20px !important;
         padding-bottom: 30px !important;
    }
}
 @media screen and (-webkit-min-device-pixel-ratio:0) {
    /* Safari only override */
     ::i-block-chrome, .dashboard_main_shadow{
        height: 90px !important; /* on Safari and Chrome  */
    }
     ::i-block-chrome, .sidebar-nav li a {
height:38px; /* on Safari and Chrome  */
    }
     ::i-block-chrome, .sidebar-nav li a svg {
width: 14px;
         float: left;
margin-left: 20px;
height: 30px;
margin-top: 0px;
    }
     ::i-block-chrome, .btn_changephoto svg {
         margin-top: 0px;
    }
     ::i-block-chrome, .btn_change_pass_pro svg {
         margin-top: 0px !important;
    }
}
