.top_mrg_general_custom_g_s {
     top: 220px;
}
.general_setting_hd h2 {
    color: #686868;
    font-size: 25px !important;
    font-weight: 600 !important;
    font-family: Segoe UI;
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 10px;
    margin-bottom: 30px;
}