.customfields_OutlineNone{
     outline:none;
}
.display_flex{
	display:flex !important;
}
.btn_margin_left{
	margin-left: 5px !important;
}
 .customfields_mt15{
     margin-top:15px;
}
 .customfields_mt20{
     margin-top:20px;
}
.custome_field_table {
    border: none !important;
}
 .customfields_btn_plus_cusotm {
    background: #ffffff;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    color: #989898 !important;
    margin-right: 15px !important;
    outline: none !important;
}
 .customfields_btn_plus_cusotm:hover {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
 .customfields_btn_plus_cusotm:focus {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}