
  .m-signature-pad--body
  {
    min-width: 200px;
    height: 200px;
    text-align: center;
  }
  .m-signature-pad--body
  canvas {
    width: 100%;
    height: 100%;
  }
  #tab_default_21{
      text-align:center;
      padding:10px;
  }
  .users_sign_Loading{
          margin-left: 30%;
          width: 40%;
  }