.noPadding{
    padding: 0px !important;
}
.login_CursorPointer{
    cursor:pointer;
}
.login_notActive{
   pointer-events: none;
   background: #4358a773;
   border: solid 1px #858fb7;
}
.login_width100Percent{
       width: 100%;
}
.verify_btn_cancel{
    padding:10px 70px 10px 70px;
    border-radius: 10px;
    color: #7b7b7b;
    font-size: 20px;
    border: 1px solid #7b7b7b;
}