.OutlineNone {
  outline: none;
}
.mt15 {
  margin-top: 15px;
}
.noHeight {
  height: 0px;
}
.noHeightProject {
  height: auto;
}
.ProjectEdit_panel > .ProjectEdit_panel_heading {
  border: none !important;
}
.ProjectEdit_panel
  > .ProjectEdit_panel_heading
  + .panel-collapse
  > .panel-body {
  border: none !important;
}
.ProjectEdit_panel_heading [data-toggle="collapse"]:after {
  font-family: "fontawesome";
  content: "\f068";
  /* "play" icon */
  font-size: 14px;
  line-height: 22px;
  -webkit-transform: rotate(-0deg);
  -moz-transform: rotate(-0deg);
  -ms-transform: rotate(-0deg);
  -o-transform: rotate(-0deg);
  transform: rotate(-0deg);
  float: left;
  color: #0b1e65;
}
.ProjectEdit_panel_heading [data-toggle="collapse"].collapsed:after {
  /* rotate "play" icon from > (right arrow) to ^ (up arrow) */
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  content: "\f067";
  /* "play" icon */
  float: left;
  color: #0b1e65;
}
/* Tabs panel */
.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}
/* Below tabs mode */

.ProjectEdit_panel_heading {
  padding-top: 9px;
}
.ProjectEdit_panel_title > a {
  display: block;
}
.ProjectEdit_panel_heading.ProjectEdit_panel_title {
  font-size: 16px !important;
}
.projectedit_panelgroup {
  margin-bottom: 5px;
}
.general_setting_hd select {
  padding: 5px 8px;
  width: 100%;
  box-shadow: none;
  padding-left: 20px;
  color: #656565;
  font-size: 16px;
  width: 100%;
  border-radius: 0px;
  border: 1px solid #cccccc;
}
.projectedit_btn_plus_cusotm {
  background: #ffffff;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  color: #989898 !important;
  margin-right: 15px !important;
  outline: none !important;
}
.projectedit_btn_plus_cusotm:hover {
  background: #ffffff;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  color: #989898 !important;
}
.projectedit_btn_plus_cusotm:focus {
  background: #ffffff;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  color: #989898 !important;
}
.mr10 {
  margin-right: 6px;
}
.ProjectEdit_panel_title a {
  font-size: 16px;
}
@media (max-width: 1439px) and (min-width: 1200px) {
  .company_setting .label {
    margin-top: 5px;
    font-size: 18px;
    color: #656565;
    font-weight: normal;
    text-align: left;
  }
}
.ProjectEdit_panel_heading {
  padding: 7px 0px 0px 15px !important;
}
.widh100percent {
  width: 100%;
}

.generate_btn {
  background: #4358a7 !important;
  border-radius: 5px !important;
  text-align: center;

  height: 31px !important;
  line-height: 22px;
  font-size: 16px;
  color: #fff;
  display: block;
  text-decoration: none;
  border: solid 1px #4358a7;
  float: left;
  font-weight: 600;
}

.generate_link_input_container {
  display: flex;
  max-width: 400px;
}

@media only screen and (max-width: 767px) {
  .generate_link_input_container {
    margin-top: 20px;
  }
}

.generate_link_input {
  height: 31px !important;
  line-height: 22px;
  font-size: 16px;
  font-weight: 600;
  background-color: #f5f5f5;
  border-right: 0px;
  margin: 0px !important;
  border-radius: 5px 0px 0px 5px !important;
}
.copy_link_button {
  width: 130px;
  background: #4358a7 !important;
  border-radius: 0px 5px 5px 0px !important;
  text-align: center;

  height: 31px !important;
  line-height: 22px;
  font-size: 16px;
  color: #fff;
  display: block;
  text-decoration: none;
  border: solid 1px #4358a7;
  float: left;
  font-weight: 600;
  position: relative;
  right: 3px;
}
