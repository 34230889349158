.OutlineNone{
    outline:none;
}

.cursorPointer{
    cursor: pointer;
}

.label_dataentry {
    padding-top:5px;
    color:#656565;
    font-size:18px;
    margin-top: 15px;
    margin-left: 25px;
}
.custom_field_code{
    font-size: 20px !important;

}
.calendar_img svg{
    width: 25% !important;
    fill: #4358a7;

}
.cursorMove{
    cursor: move;
}
.panel_width{
    width: 25%;
    background-color: transparent !important;
    color: #4358a7 !important;

    border: none;
}
.panel_width a {
    color: #4358a7 !important;

}
.panel_width [data-toggle="collapse"].collapsed:after{
    color: #4358a7 !important;
}

.drag_files_text label{
    font-weight: 500 ;
    margin-top: 10px;
}
.mt15{
    margin-top:15px;
}
.mt22{
    margin-top:22px;
}
.idNoDisplay{
    display:none;
}
.text_margin_bottom{
    margin-bottom:30px;
}

.id_btn_plus_cusotm {
    background: #ffffff;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    color: #989898 !important;
    margin-right: 15px !important;
    outline: none !important;
}
 .id_btn_plus_cusotm:hover {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
 .id_btn_plus_cusotm:focus {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}

.id_main_label {
    color: #5A5A5A;
    font-size: 25px;
    font-weight: 600;
}

/**************/
.approvel_field_table th {
            font-weight: 600 !important;
            background: #E3E3E3 !important;
            color: #86878A;
            font-size: 15px;
            padding-left: 20px !important;
        }

        .faqHeader {
            font-size: 27px;
            margin: 20px;
        }

        .panel-heading [data-toggle="collapse"]:after {
            font-family: 'fontawesome';
            content: "\f068";
            /* "play" icon */
            float: right;
            color: #989898;
            font-size: 14px;
            line-height: 22px;
            -webkit-transform: rotate(-0deg);
            -moz-transform: rotate(-0deg);
            -ms-transform: rotate(-0deg);
            -o-transform: rotate(-0deg);
            transform: rotate(-0deg);
        }

        .panel-heading [data-toggle="collapse"].collapsed:after {
            /* rotate "play" icon from > (right arrow) to ^ (up arrow) */
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
            color: #989898;
            content: "\f067";
            /* "play" icon */
        }

        .carousel-indicators li {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin: 0px;
            text-indent: -999px;
            cursor: pointer;
            background-color: #E6E6E6;
            border: 1px solid #E6E6E6;
            border-radius: 10px;
        }

        .carousel-indicators .active {
            background-color: #858789;
            border: 1px solid #858789;
            width: 12px;
            height: 12px;
        }

        .carousel-control.left {
            box-shadow: none !important;
            background-image: inherit !important;
        }

        .carousel-control.right {
            box-shadow: none !important;
            background-image: inherit !important;
        }



        .panel-heading {
            padding-top: 20px;
        }

        .panel-title > a {
            display: block;
        }

        .thumbnail-borderless .thumbnail {
            border: none;
            padding: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }


        .approvel_new_open_table td table tr td {
            padding-bottom: 6px !important;
            padding-top: 6px !important;
            color: #7B7B7B !important;
            font-size: 14px !important;
            text-align: left;
            white-space: nowrap;
        }

        .approvel_new_open_table table {
            margin-bottom: 0px !important;
            color: #7B7B7B !important;
            font-size: 12px !important;
        }

        .approvel_new_open_table td {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }

        .check_box_pop {
            font-size: 14px;
            font-weight: normal;
            float: left;
            padding-left: 0px;
        }

        .checkmark {
            left: 60px;
            height: 20px;
            width: 20px;
        }

        .check_box_pop .checkmark:after {
            left: 7px;
            top: 3px;
        }

        .width_check_form_dtl_pop {
            width: 25%;
            float: left;
        }

        .width_check_form_dtl_pop2 {
            width: 20%;
            float: left;
        }

        .width_check_form_dtl_pop2 .checkmark {
            left: 38px;
        }

        .width_check_form_dtl_pop3 {
            width: 28%;
            float: left;
        }

        .width_check_form_dtl_pop3 .checkmark {
            left: 70px;
        }

        .width_check_form_dtl_pop4 {
            width: 25%;
            float: left;
        }

        .width_check_form_dtl_pop5 {
            width: 22%;
            float: left;
        }

        .width_check_form_dtl_pop5 .checkmark {
            left: 40px;
        }

        .width_check_form_dtl_pop6 {
            width: 30%;
            float: left;
        }

        .width_check_form_dtl_pop6 .checkmark {
            left: 69px;
        }

        .width_check_form_dtl_pop7 {
            width: 30%;
            float: left;
        }

        .mrg_top_from8 {
            margin-top: 7px;
        }

        .left0_check {
            left: 0px !important;
        }
        @media (min-width: 1024px) {
            .construction_dropdown{
                margin-left: -20px !important;
            }
            .id_label_select_option{
                font-size: 14px !important;
            }

        }
        @media (min-width: 767px) and (max-width:1023px){
            .construction_dropdown{
                margin-left: 0px !important;
            }
        }

        @media (min-width: 767px) {

            .id_label_select_option{
                line-height: 60px !important;
            }
        }
        @media (min-width: 320px) and (max-width: 767px) {
            .form_margin_zero{
                margin-top:0px !important;
            }
            .label_form_margin{
                margin-left: 0px !important;
            }
            .contract_margin_top{
                margin-bottom: 15px !important;
                margin-top: 0px !important;
            }
            .label_dataentry{
                font-size: 16px;
                margin-bottom: 5px;
            }
            .check_box_pop .checkmark:after {
                left: 7px;
                top: 3px;
            }
            .width_check_form_dtl_pop {
                width: 50%;
                float: left;
            }
            .width_check_form_dtl_pop2 {
                width: 50%;
                float: left;
            }
            .width_check_form_dtl_pop2 .checkmark {
                left: 38px;
            }
            .width_check_form_dtl_pop3 {
                width: 50%;
                float: left;
            }
            .width_check_form_dtl_pop3 .checkmark {
                left: 70px;
            }
            .width_check_form_dtl_pop4 {
                width: 50%;
                float: left;
            }
            .width_check_form_dtl_pop5 {
                width: 50%;
                float: left;
            }
            .width_check_form_dtl_pop5 .checkmark {
                left: 40px;
            }
            .width_check_form_dtl_pop6 {
                width: 50%;
                float: left;
            }
            .width_check_form_dtl_pop6 .checkmark {
                left: 69px;
            }
            .width_check_form_dtl_pop7 {
                width: 50%;
                float: left;
            }
            .approvel_new_open_table table th {
                font-size: 12px !important;
                white-space: nowrap;
                padding-left: 6px !important;
            }
            .custom_field_tab td {
                padding-left: 6px !important;
            }
        }


.bill{
    margin-bottom: 20px;
padding: 5px 8px;
width: 100%;
border: none;
box-shadow: none;
background-color: transparent;
/*background-image: url(../images/down_arrow_amprvoe.png);*/
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
padding-left: 20px;
color: #656565;
font-size: 16px;
border: 1px solid #ccc;
width: 100%;
border-radius: 0px;
background-repeat: no-repeat;
background-position: right;
background-origin: content-box;
}
.id_form_detail_select{
    /* width: 100%;
    padding: 5px 8px;
    font-size: 16px;
    color: #656565;
    border: 1px solid #ccc;
    margin: 0 0 22px 0;
    background-image: url(../../assets/images/down_arrow_amprvoe.png); */

    margin-top:20px;
    margin-bottom: 0px;
    padding: 5px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: url(../../assets/images/down_arrow_amprvoe.png);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 20px;
    color: #656565;
    font-size: 16px;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 0px;
    background-repeat: no-repeat;
    background-position: right;
    background-origin: content-box;
}

.id_panel_side_by{
    border: 1px solid #E6E6E6 !important;
    padding: 0px 15px !important;
}

.id_approver_table table thead tr th {
    font-size: 17px;
    font-weight: 600;
    color: #7B7B7B;
    padding: 10px 0px !important;
}

.id_approver_table table tbody tr td {
    font-size: 16px;
    color: #7B7B7B;
    padding: 10px 0px !important;
}
.id_approver_table table th {
    background: #E3E3E3;
    border: 1px solid #E3E3E3 !important;
}

.id_label_select_option{
    color: #656565;
    font-weight: 600;
    font-size: 17px;
    line-height: 32px;
}
.id_custom_list_edit_pencil a{
    border: 1px solid #D6D6D6;
    padding: 5px 5px 5px 5px;
    border-radius: 3px;
    margin-left: 11px;
}

.id_custom_list_edit_pencil svg {
    color: #acacac !important;
    fill: #acacac !important;
    width: 18px;
    height: 18px;
    position: relative;
    top: 3px;
}


.id_custom_list_table table th {
    font-weight: 600 !important;
    background: #C4C4C4 !important;
    color: #fff;
    font-size: 15px !important;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 10px !important;
}
.id_custom_list_table td {
    padding: 10px 0px !important;
}

.id_custom_list_table td {
    font-size: 14px;
    color: #86878A;
}
.id_main_inner_label{
    color: #656565;
    font-size: 16px;
    font-weight: 600;
}
#insert_document_dropdown_save_btn .dropdown-menu>li>a {
    font-weight: 600 !important;
    color: #ACACAC !important;
}
#insert_document_dropdown_save_btn svg {
    fill: #ACACAC;
    width: 16px;
    height: 14px;
    position: relative;
    top: 1px;
    margin-right: 5px;
}
#insert_document_dropdown_save_btn .dropdown-menu {
    top: 35px !important;
}
#insert_document_dropdown_save_btn .caret {
    border-top: 6px solid;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    position: relative;
    bottom: 1px;
    left: 5px;
}

.insert_document_capitalize {
    text-transform: capitalize;   
}

.validation {
    color: #FF0000;
    margin-bottom: 0px;
    font-size: 12px;
     float: right; 
    position: absolute;
    right: 0;
}

.CustomCodeList_DatePicker  input
{
border:none;
width:80px;
}

.dropzone-custom {
    min-width: 100%;
    min-height: 37px !important;
    font-size: 15px !important;
   
}
.dropzone-custom svg {
    margin-top: 7px !important;
    width: 32px !important;
    height: 20px !important;
    position: relative;
    top: 0px;
    right: 5px;
}
.dropzone-custom p {
    display: inline-block;
    vertical-align: initial;
    margin-bottom: 2px;
}
.ml-35 {
    margin-left: 35px;
}
.ml-0 {
    margin-left: 0 !important;
}

.form__display--between{
    justify-content: left !important;
}

.file-item {
    padding: 8px;
    border-radius: 8px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;;
}
.file-item button {
    margin-left: 10px;
    background-color: #ccc;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #4358a7;
}
.file-item span {
    max-width: 80%;
     white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}