.bL0px {
  border-left: 0px;
}
.report-select-style {
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  background-color: #fff;
  background: #fff url(../../assets/images/down_arrow.jpg) no-repeat 98% 50%;
}
.report_checkmark {
  left: 0px !important;
  height: 25px !important;
  width: 25px !important;
}
.report_checkmark:after {
  left: 8px !important;
  top: 5px !important;
}
.report-select-style select {
  padding-left: 10px !important;
}
.report_check_box_container {
  margin-top: 5px;
}
.report_label {
  padding-top: 5px;
  color: #656565;
  font-size: 18px;
}
