.sign-tabbable-panel {
     border:1px solid #eee;
     padding: 0px;
}
 #tab_default_1 {
     padding:50px;
     text-align:center !important;
}
 #tab_default_2 {
     padding: 0;
     text-align:center !important;
}
/* Default mode */
 .tabbable-line_ss > .nav-tabs {
     border: none;
     margin: 0px;
     background:#F9F9F9;
}
 .tabbable-line_ss > .nav-tabs > li {
     margin-right: 2px;
     width:49%;
}
 .tabbable-line_ss > .nav-tabs > li svg {
     width:20px;
     height:14px;
     color:#ACACAC;
     fill:#ACACAC;
}
 .tabbable-line_ss > .nav-tabs > li > a {
     border: 0;
     margin-right: 0;
     color: #ACACAC;
}
 .tabbable-line_ss > .nav-tabs > li > a > i {
     color: #a6a6a6;
}
 .tabbable-line_ss > .nav-tabs > li.open, .tabbable-line_ss > .nav-tabs > li:hover {
}
 .tabbable-line_ss > .nav-tabs > li.open > a, .tabbable-line_ss > .nav-tabs > li:hover > a {
     border: 0;
     background: none !important;
     color: #333333;
}
 .tabbable-line_ss > .nav-tabs > li.open > a > i, .tabbable-line_ss > .nav-tabs > li:hover > a > i {
     color: #a6a6a6;
}
 .tabbable-line_ss > .nav-tabs > li.open .dropdown-menu, .tabbable-line_ss > .nav-tabs > li:hover .dropdown-menu {
     margin-top: 0px;
}
 .tabbable-line_ss > .nav-tabs > li.active {
     position: relative;
     background-color:#F9F9F9;
}
 .tabbable-line_ss > .nav-tabs > li.active > a {
     border: 0;
     color: #02477C;
     background-color:#F9F9F9;
     font-size:18px;
}
 .tabbable-line_ss > .nav-tabs > li.active > a svg {
     color: #02477C;
     fill:#02477C;
}
 .tabbable-line_ss > .nav-tabs > li.active > a > i {
     color: #404040;
}
 .tabbable-line_ss > .tab-content-signsetting {
     margin-top: -3px;
     background-color: #fff;
     border: 0;
     border-top: 1px solid #eee;
     padding: 0;
}
 .portlet .tabbable-line_ss > .tab-content-signsetting {
     padding-bottom: 0;
}
/* Below tabs mode */
 .tabbable-line_ss.tabs-below > .nav-tabs > li {
     border-top: 4px solid transparent;
}
 .tabbable-line_ss.tabs-below > .nav-tabs > li > a {
     margin-top: 0;
}
 .tabbable-line_ss.tabs-below > .nav-tabs > li:hover {
     border-bottom: 0;
     border-top: 3px solid #fbcdcf;
}
 .tabbable-line_ss.tabs-below > .nav-tabs > li.active {
     margin-bottom: -2px;
     border-bottom: 0;
     border-top: 3px solid #f3565d;
}
 .tabbable-line_ss.tabs-below > .tab-content-signsetting {
     margin-top: -10px;
     border-top: 0;
     border-bottom: 1px solid #eee;
     padding-bottom: 15px;
}
 .tabbable-line_ss > .nav-tabs > li > a {
     font-size:18px !important;
     font-family: Segoe UI !important;
     font-weight:600;
}
 .ss_nav_tabs>li {
     float: left;
     margin-bottom: 4px;
}
 .mrg_dashboard_right_s_s {
     margin-top:60px;
}
 @media (min-width: 768px) and (max-width: 991px){
     .mrg_dashboard_right_s_s {
         width: 95% !important;
    }
}
 @media (min-width: 320px) and (max-width: 767px){
     #tab_default_1 {
         padding: 10px;
    }
     .mrg_dashboard_right_s_s {
         margin-top:10px;
    }
     .ss_tab_pane img {
         width:100%;
    }
    
     .sign-tabbable-panel{
         border: 1px solid #E9E9E9;
         padding:0px;
    }
     .tabbable-line_ss > .nav-tabs > li.active {
    }
     .tabbable-line_ss > .nav-tabs > li {
         border-bottom: solid 2px #F9F9F9;
    }
     .tabbable-line_ss > .nav-tabs > li.open, .tabbable-line_ss > .nav-tabs > li:hover {
         border-bottom: 2px solid #F9F9F9;
    }
     .tabbable-line_ss > .nav-tabs > li > a {
         font-size:18px !important;
    }
     .mrg_dashboard_right_s_s {
         margin-top: 0px;
    }
     .mrg_dashboard_right_s_s {
         width: 95% !important;
    }
}
 .svg_down_pencil svg {
     width:15px !important;
     height:15px !important;
}
 .nav > li > a {
     padding-top:20px !important;
     padding-bottom:20px !important;
}
 .btn_save_pro {
     background: #357AAE;
     border-radius: 5px !important;
     text-align: center;
     width: 97px !important;
     height: 31px !important;
     line-height: 22px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border: solid 1px #357AAE;
     float: left;
     font-weight: 600;
}
 .tab-content-signsetting > .active {
     border: solid 1px #F9F9F9;
     margin:0;
     margin-top:0px;
     margin-bottm:20px;
}
 .nav>li>a:hover, .nav>li>a:focus {
     background: #F9F9F9;
}
 .text_right_tab1 {
     text-align:right !important;
     padding-right:80px !important;
}
 .text_left_tab2 {
     text-align:left !important;
     padding-right:80px !important;
}
 .mL50px{
     margin-left:50px;
}
.mT20px{
     margin-top:20px;
}
 #tab_default_1 {
     padding: 50px;
     text-align: center !important;
}
 .tabbable-line_ss > .nav-tabs > li.active > a {
     border: 0;
     color: #02477C;
     background-color: #F9F9F9;
     font-size: 18px;
}
 .tabbable-line_ss > .nav-tabs > li > a {
     font-size: 18px !important;
     font-family: Segoe UI !important;
     font-weight: 600;
}
 .tabbable-line_ss > .nav-tabs > li.active > a svg {
     color: #02477C;
     fill: #02477C;
}
 .tabbable-line_ss > .nav-tabs > li svg {
     width: 20px;
     height: 14px;
     color: #ACACAC;
     fill: #ACACAC;
}
 .nav > li > a {
     padding-top: 20px !important;
     padding-bottom: 20px !important;
}
 .text_right_tab1 {
     text-align: right !important;
     padding-right: 80px !important;
}
 .tabbable-line_ss > .nav-tabs > li {
     margin-right: 2px;
     width: 49%;
}
 .text_right_tab1 {
     text-align: right !important;
     padding-right: 80px !important;
}
 .tabbable-line_ss > .nav-tabs > li.active {
     border:0 !important;
}
 @media (min-width: 320px) and (max-width: 767px){
     .mL50px{
         margin-left:8%;
    }
     #tab_default_2 {
         padding:0px;
    }
     #tab_default_1 {
         padding:0px;
    }
}