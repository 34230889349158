.company_detail_overflow_inherit{
    overflow:inherit !important
}
.OutlineNone{
    outline:none;
}
.modal2 {
  display: block;
}
.mt15{
    margin-top:15px;
}

.company_detail_btn_plus_cusotm {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
    margin-right:15px !important;
    outline:none !important;
}
.company_detail_btn_plus_cusotm:hover {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
}
.company_detail_btn_plus_cusotm:focus {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
}
.btn_save_pro_notactive {
     background: #4358a773;
    border-radius: 5px !important;
    text-align: center;
    width: 97px !important;
    height: 31px !important;
    line-height: 22px;
    font-size: 16px;
    color: #fff;
    display: block;
    text-decoration: none;
    border: solid 1px #858fb7;
    float: left;
    font-weight: 600;
    pointer-events: none;
}
.general_setting_hd .check_box_pop {
    font-size:18px;
    font-weight:normal;
    float:left;
    padding-left: 0px;
}
.general_setting_hd  .checkmark {
    left: 10px;
    height: 20px;
    width: 20px;
}
.general_setting_hd .left0_check {
    left: -200px !important;
    top:8px;
}
.general_setting_hd .label_csutom_pop {

    clear: both;
}