 .formtemplate_OutlineNone{
     outline:none;
}
 .formtemplate_mt15{
     margin-top:15px;
}
 .formtemplate_btn_plus_cusotm {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
     margin-right:15px !important;
     outline:none !important;
}
 .formtemplate_btn_plus_cusotm:hover {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
 .formtemplate_btn_plus_cusotm:focus {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
 .formtemplate_custome_field_table {
     border: none;
}
 .formtemplate_custome_field_table {
     border:none;
}
 .formtemplate_custome_field_table th {
     font-weight:600 !important;
     background:#29429d;
     color:#fff;
     text-align:left !important;
     font-size:15px;
     padding-left:20px !important;
}
 .formtemplate_custome_field_table td {
     text-align:left !important;
     padding-left:20px !important;
}
.v_center td{
    padding-bottom:0px !important;
}
