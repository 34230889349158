.customfieldlist_OutlineNone{
     outline:none;
}
 .customfieldlist_mt22{
     margin-top:22px;
}
 .customfieldslist_noBorder{
     border:none !important;
}
 .customfieldslist_modalBodyCustomStyle{
     overflow:scroll !important;
     height:auto;
}
 .customfieldslist_cloxebtN{
     background: none;
     outline: none;
     border: none;
     float: right;
}
.customfieldlist_btn_plus_cusotm {
    background: #ffffff;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    color: #989898 !important;
    margin-right: 15px !important;
    outline: none !important;
}
 .customfieldlist_btn_plus_cusotm:hover {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
 .customfieldlist_btn_plus_cusotm:focus {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
.customfieldslist_padding{
    padding-right: 40px;
}
.customfielddlist_pen_custom_list_pop{
    height: 33px;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 2.8rem !important;
}
.react-datepicker{
        font-size: 1.5rem !important;
}
.react-datepicker__current-month, .react-datepicker-time__header{
    font-size: 1.5rem !important;
}
.cursorMove{
    cursor: move;
}

/*.timecard_table {
    table-layout: fixed !important;
}

.timecard_table tbody td{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}*/

.overflow_scroll_sm_list{
    overflow-x: scroll;
}
.CustomFieldList_date input
{

    border: none;
    font-size: 12px;
    text-align: end;
    width: 90px;
    height: 15px;
    padding: 12px;
    margin-left: -21px;
    margin-top: -8px
}

.s_button
{
    width: 38px;
    height: 31px !important;
}
.s_button img{
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
}
.hdg_edit_detail_1
{
    font-size: 20px;
    color: #656565;
    padding-bottom: 6px !important;
    margin-bottom: 1px;
    font-weight: 600;
    margin-left: 17px;
}
.hdg_edit_detail_1 h4
{
    font-size: 22px;
    color:#86878A;
    font-weight: 600;
	margin:0px;
	padding:0px;
    margin-bottom: 7px;
    margin-left: -15px;
   
}
.customfieldslist_modalBodyCustomStyleSetup{
    overflow-x:hidden !important;
    overflow-y: scroll;
    height:auto;
}
.hdg_edit_detail_2_setup
{
    font-size: 20px;
    color: #656565;
    padding-bottom: 6px !important;
    margin-bottom: 1px;
    font-weight: 600;
    margin-left: 17px;
    
}
.hdg_edit_detail_2_setup h4
{
    font-size: 22px;
    color:#86878A;
    font-weight: 600;
	margin:0px;
	padding:0px;
    margin-left: -13px;
    margin-top: 10px;
}
.setupFields_plus_button
{
    text-align: right;
    margin-right: 15px;
}
.bdr_tr_none
{
	border:none !important;
}

.bdr_tr_none td
{
	border:none !important;
	height:25px !important;
	line-height:33px !important;
}
.header_setup_team_pop3
{
	background:#E3E3E3;
	/* border-radius:5px; */
	color:#7B7B7B;
	font-size:18px;
	font-weight:600;
	text-align:left;
	padding:5px;
	padding-left:40px;
}
.table_header_adtimes
{
		border:solid 1px #30679A !important;
		border-radius:3px;
}
.th_p0
{
	padding:0px !important;
}
.setup_team_pop3_svg svg
{
	margin-left:10px;
	color: #acacac !important;
    fill: #acacac !important;
    width: 15px;
    height: 15px;
}


.checkbox {
    padding-left: 20px;
margin-top: 5px; }
.checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 5px; }
.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 2px solid #7B7B7B;
    border-radius: 0px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0px !important;
    top: 0px !important;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 2px;
    font-size: 11px !important;
    color: #555555;
    line-height: 14px; 
}
#name_details .checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0px !important;
    top: 0px !important;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 2px !important;
    font-size: 11px !important;
    color: #555555;
    line-height: 14px; 
}
.checkbox input[type="checkbox"] {
    opacity: 0; }
.checkbox input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
.checkbox input[type="checkbox"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f00c"; }
.checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65; }
.checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed; }
.checkbox.checkbox-circle label::before {
    border-radius: 50%; }
.checkbox.checkbox-inline {
    margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #428bca;
    border-color: #428bca; }
.checkbox-primary input[type="checkbox"]:checked + label::after {
    color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f; }
.checkbox-danger input[type="checkbox"]:checked + label::after {
    color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de; }
.checkbox-info input[type="checkbox"]:checked + label::after {
    color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e; }
.checkbox-warning input[type="checkbox"]:checked + label::after {
    color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c; }
.checkbox-success input[type="checkbox"]:checked + label::after {
    color: #fff; }


    .d_value_dropdown_styles{
        width:50% !important;
        display: flex;
    flex-flow: row;
    }
    .d_value_dropdown_styles svg
{
margin-left: 15px;
margin-top:8px;
height:17px;
width:20px;
}

.approval_edit_pop_input
{
    background:#E3E3E3;
	/* border-radius:5px; */
	color:#7B7B7B;
	font-size:18px;
	font-weight:600;
	text-align:left;
	padding:5px;
	padding-left:40px;
}
.drop_list_modal_title
{
    font-weight: 500 !important;
    font-size: 18px !important;
    margin-left:11px;
}
.drop_list_tableHead
{
    background-color: #EEEE;
}
.tba-box {
    border: solid 1px #E6E6E6;
    margin-left: 15px;
    width:188px
}
.pad-0 {
    padding: 0;
}
.new-chekbox {
    width: 20px !important;
    height: 20px !important;
}
.new-chekbox:after {
    left: 7px !important;
    top: 3px !important;
}

.form__display--props{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    align-self: center;
}
.form__date--picker{
    display: flex;
   
    align-self: center;
  
}
.Svg--icons--picker{
    width: 18px;
    margin-top: 5px;
    margin-left: 1rem;
        }
        .svg__color--blue{
            fill: #357aae;
        }
        

        .form__display--between{
            justify-content: left !important;
        }