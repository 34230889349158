#menu-toggle_mobile{
     float:left !important;
     line-height:normal !important;
}
 .menutoggleimg{
     width:25px !important;
     height:18px !important;
}
 .logoutAcnhortag{
     border-left:0px;
}
 .mL0px{
     margin-left: 0px !important;
}
 #menu-toggle{
     float:right;
     line-height:normal;
}
 .displayNone{
     display:none;
}
 .sidebar-nav{
     margin-left:0;
}
 .modal_header_register{
     min-height: 45.43px;
}
 #svgPlus{
     margin-right: 5px;
}
 @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
     #menu-toggle_mobile img{
         width: 25px !important;
         height: 18px;
    }
     .btn_document_Dash{
         padding: 5px 20px !important;
    }
}
 .OutlineNone{
     outline:none;
}
 .mt15{
     margin-top:15px;
}
 .mH45px{
     min-height: 45.43px;
}
 .CursorPointer{
     cursor: pointer;
}
 .displayNoneimp{
     display: none;
}
 .modal-backdrop{
     z-index: 99;
}
 .dashboard_btn_plus_cusotm {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
     margin-right:15px !important;
     outline:none !important;
}
 .dashboard_btn_plus_cusotm:hover {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
 .dashboard_btn_plus_cusotm:focus {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
.dashboard_plus_icon_table {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
    margin-right:15px !important;
    outline:none !important;
}
.dashboard_plus_icon_table:hover {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
}
.dashboard_plus_icon_table:focus {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
}
.dashboard_checkmark{
    height: 25px !important;
    width: 25px !important;
}
.dashboard_checkmark:after {
    left: 9px !important;
    top: 5px !important;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
}
.display_hidden{
    display: none !important;
}

.raphael-group-21-creditgroup{
    display: none !important;
}

svg:not(:root) {
    overflow: visible;
  }

.label_modal_cp input {
    border: solid 1px #E6E6E6;
    color: #656565;
    font-size: 15px;
    border-radius: 0px;
    box-shadow: none;
    padding-left: 10px;
    height: 33px;
    background: #fff;
    width: 100%;
    outline: none;
}