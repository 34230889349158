.fpstepthird_CursorPointer{
     cursor:pointer;
}
 .forgot_modal_header {
     background: #98e6b0;
     padding-top: 30px;
     padding-bottom: 30px;
     text-align: center;
     border-radius: 5px;
     border: none !important;
}
 .forgot_modal_body {
     color: #86878A;
     font-size: 35px;
     text-align: center;
     padding-top: 55px;
     padding-bottom: 70px;
     font-weight: bold;
}
 .modal-header .close {
     margin-top: -23px !important;
     color: #ffffff;
     opacity: 1 !important;
}

.fp3_validation {
    color: #FF0000;
    margin-bottom: 0px;
    font-size: 12px;
    float: right;
    position: absolute;
}
