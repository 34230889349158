
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 54px;
    height: 300px;
}
.ql-toolbar.ql-snow{
    background: #f9f9f9 !important;
}


.emailtemplates_OutlineNone{
    outline:none;
}
.emailtemplates_mt15{
    margin-top:15px;
}
.dropdown-menu_emiltemp_place {
    z-index:2 !important;
}
.attachmentlist {
    width:40px; 
    height: 40px; 
    float: left;
    margin-right: 10px;
}
.attachmentinlist{
    width: 100%
}
.mrg_top_emiltep {
    margin-top: 44px;
    width: 450px;
    left: -71%;
    box-shadow: none;
    border-radius: 0px;
    background-color:#F9F9F9;
    border:none !important;
    padding:20px;
    color: #656565;
    font-weight: normal !important;
    font-family: Segoe UI;
}
.mrg_top5_labels {
    margin-top:15px !important;
}
.mrg_top_emiltep h2 {
    font-size: 20px !important;
    color: #7B7B7B;
    font-weight: 600 !important;
    font-family: Segoe UI;
    margin-top: 0px;
    margin-bottom:10px;
    border:none !important;
}
.email_attach_table_desc_pad {
    padding-left: 14px;
    font-weight: bold;
}
.btn_email_temp_status {
    background:#fff;
    font-size: 16px !important;
    color: #7B7B7B;
    font-weight: normal !important;
    font-family: Segoe UI;
    border:solid 1px #C4C4C4;
    text-align:center;
    padding:3px;
    padding-left:8px;
    padding-right:8px;
    border-radius:3px;
    margin-right:10px;
    margin-bottom:10px;
}
.btn_email_temp_status:hover {
    background:#29429D;
    color: #fff;
    border:solid 1px #29429D;
}
.close_mrg_email {
    float:right;
    margin-top:-10px;
}
.insert_place_bg_w {
    background:#fff;
    padding:15px;
    padding-top:25px;
    padding-bottom:25px;
}
.email_temp_add_box3_spn {
    color:#FF0000;
}
.email_btn_insert_place {
    border:solid 1px #D6D6D6;
    font-size:17px;
    font-weight:normal;
    color:#5A5A5A !important;
    font-family:Segoe UI;
    text-align:center;
    padding:5px;
    padding-left:7px;
    padding-right:30px;
    border-radius:5px;
    background-color:transparent !important;
}
.email_add_file_btn {
    border:solid 1px #E3E3E3 !important;
    font-size:17px;
    font-weight:normal;
    color:#5A5A5A !important;
    font-family:Segoe UI;
    text-align:center;
    padding:5px;
    width: 100% !important;
    padding-left:30px;
    padding-right:30px;
    border-radius:5px !important;
    background-color:transparent !important;
}
.email_add2_rightbox {
    border:solid 1px #E3E3E3;
    border-radius:3px;
    width:100%;
    height:100vh;
}
.email_right_logo_label {
    text-align:left;
    font-size:30px;
    color:#5A5A5A;
    font-family:Segoe UI;
    font-weight:600;
    padding:30px;
}
.email_right_logo_label img {
    float:left;
    width:60px;
    margin-right:20px;
}
.emailadd_left_boxarea {
    font-size:15px;
    color:#656565;
    padding-top:20px;
    text-align:left !important;
}
.emailadd_left_boxarea input {
    font-size: 14px;
    color: #656565;
    font-weight: bold;
    font-family:Segoe UI;
    text-align:left;
    font-weight:normal;
    margin-right:20px;
    border:solid 1px #E3E3E3;
    width:25%;
    height:30px;
    margin-top:5px;
    padding:5px;
}
.emailadd_left_boxaaea select {
    font-size: 14px;
    color: #656565;
    font-weight: bold;
    font-family:Segoe UI;
    text-align:left;
    font-weight:normal;
    margin-right:20px;
    border:solid 1px #E3E3E3;
    width:25%;
    height:30px;
    margin-top:5px;
    padding:5px;
}
.email_temp_add_box3 {
    text-align:left;
    font-size:16px;
    color:#2A2A2A;
    font-family:Segoe UI;
    font-weight:600;
}
.email_temp_add_box3 input {
    font-size: 14px;
    color: #656565;
    font-weight: bold;
    font-family:Segoe UI;
    text-align:left;
    font-weight:normal;
    border:solid 1px #E3E3E3;
    width:100%;
    height:40px;
    padding:5px;
    border-radius:5px;
    outline:none;
    padding-left:10px;
}
.emailtemplate_edit_place_email {
    font-size: 14px;
    padding: 7px;
    padding-left:15px;
    padding-right:15px;
    line-height: 15px;
    height: auto !important;
    margin-bottom: 10px;
    background: #ffffff;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    color: #989898 !important;
    outline: none !important;
    margin-top:5px;
    margin-right:15px;
    font-weight:normal;
}
.emailtemplate_edit_place_email:hover {
    font-size: 14px;
    color:#fff !important;
    background:#29429D;
    border: solid 1px #29429D;
}
.attachmentlist svg
{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -7px;
    right: -6px;
    fill:red;
    cursor: pointer;
}
.attachmentlist
{
    padding:5px;
    border: solid 1px #357AAE;
    width: auto;
    height: auto;
    float: left;
    position: relative;
    margin-bottom: 10px;
    color: #357AAE;
    cursor: default;
}
.attachmentlist img
{
    width:100%;
    height: 100%;
}
@media (min-width: 768px) and (max-width: 991px){
    .emailadd_left_boxarea {
        font-size: 12px;
        color: #656565;
        padding-top: 20px;
   }
    .emailtemplate_general_setting_hd select {
        padding:5px !important;
        font-size:12px;
   }
}
@media (min-width: 320px) and (max-width: 767px){
    .email_btn_insert_place {
        font-size: 11px;
        padding: 5px;
        padding-left: 5px;
        padding-right: 5px;
   }
   .mrg_top5_labels {
    margin-top:30px !important;
    }
    .email_add_file_btn {
        width: 100% !important;
    }
    .mrg_top_emiltep {
        margin-top: 33px;
        width: auto;
        left:0;
   }
    .mrg_top_emiltep h2 {
        font-size: 13px 
   }
    .btn_email_temp_status {
        display:block;
        width:90px;
        float:left;
   }
    .emailtemplate_email_temp_pop_placeholder {
        height:auto !important;
        overflow:inherit !important;
   }
    .emailtemplate_edit_place_email {
        font-size: 9px;
        padding: 7px;
        padding-left: 3px;
        padding-right: 3px;
   }
    .emailtemplate_edit_place_email:hover {
        font-size: 9px;
        padding: 7px;
        padding-left: 3px;
        padding-right: 3px;
   }
    .emailtemplate_btn_plus_cusotm {
        margin-right: 5px !important;
   }
    .emailtemplate_pop_overflow_email3 {
        overflow-x:scroll;
   }
}
.emailtemplate_modal-header .close{
    margin-top: 0px !important;
}
.close{
    margin-top: 20px
}
emailtemplate_modal-header{
   padding: 15px !important;
}
.emailtemplates_btn_plus_cusotm {
   background: #ffffff;
   border: solid 1px #c4c4c4;
   border-radius: 5px;
   color: #989898 !important;
   margin-right: 15px !important;
   outline: none !important;
}
.emailtemplates_btn_plus_cusotm:hover {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
}
.emailtemplates_btn_plus_cusotm:focus {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
}
.modal_customefield {
   font-size: 25px;
   color: #86878A;
   font-family: Segoe UI !important;
   font-weight: 600;
}
.emailtemplates_mt22px{
   margin-top: 22px !important;
}

.emailtemplate_btn_plus_cusotm {
   background: #ffffff;
   border: solid 1px #c4c4c4;
   border-radius: 5px;
   color: #989898 !important;
   margin-right: 15px !important;
   outline: none !important;
}
.emailtemplate_btn_plus_cusotm:hover {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
}
.emailtemplate_btn_plus_cusotm:focus {
    background:#ffffff;
    border:solid 1px #c4c4c4;
    border-radius:5px;
    color:#989898 !important;
}
.emailtemplate_checkmark{
   height: 25px !important;
   width: 25px !important;
}

.settingslink{
    border-left: 0px
}
.btn_placeholders
{
    float:left;
    margin-bottom: 10px;
    height: 30px;
}
.name_w_email{
    width: 100% !important
}
.ql-editor{
    font-weight: normal !important;
}