.text_center{
	text-align: center !important;
}
.innertd img{
	float: left !important;
}
.innertr{
	border:none !important;
}
.clr_tr_bg_entry_form2_custom{
	background:#fff !important;
}
.innertd{
	text-align:center !important;
	padding-left: 0px !important;
}
.display_table_detail {
	display: table-row;
}
.hide_table_detail{
	display:none !important;
}
.check_box_pop{
	margin-top: 20px;
}
.fileupload{
	margin-top: 17px;
}
.form_detail_iput{
	margin-bottom: 0px !important;
	margin-top: 20px !important;
}
.formtemplatedetail_OutlineNone{
     outline:none;
}
 .formtemplatedetail_mt15{
     margin-top:15px;
}
 .formtemplatedetail_mt22{
     margin-top:22px;
}
 .formtemplatedetail_hide{
     display:none;
}
 .noBorder{
     border:none !important;
}
 .formtemplatedetail_borderSolidGrey{
     border:solid 1px #E3E3E3 !important;
}
 .centerAlign{
     text-align:center !important;
}
 .transparentBackground{
     background-color: transparent !important;
}
 .noFloat{
     float:none;
}
 .formtemplatedetail_approvel_field_table th {
     font-weight: 600 !important;
     background: #E3E3E3 !important;
     color: #86878A;
     font-size: 15px;
     padding-left: 20px !important;
}
 .formtemplatedetail_panel_heading [data-toggle="collapse"]:after {
     font-family: 'fontawesome';
     content: "\f068";
    /* "play" icon */
     float: right;
     color: #989898;
     font-size: 14px;
     line-height: 22px;
     -webkit-transform: rotate(-0deg);
     -moz-transform: rotate(-0deg);
     -ms-transform: rotate(-0deg);
     -o-transform: rotate(-0deg);
     transform: rotate(-0deg);
}
 .formtemplatedetail_panel_heading [data-toggle="collapse"].collapsed:after {
    /* rotate "play" icon from > (right arrow) to ^ (up arrow) */
     -webkit-transform: rotate(90deg);
     -moz-transform: rotate(90deg);
     -ms-transform: rotate(90deg);
     -o-transform: rotate(90deg);
     transform: rotate(90deg);
     color: #989898;
     content: "\f067";
    /* "play" icon */
}
 .formtemplatedetail_panel_heading {
     padding-top:20px;
}
 .formtemplate-panel-title > a {
     display:block;
}
 .formtemplate-btn-file{
    overflow:hidden;
    position:relative;
    vertical-align:middle;
}
.formtemplate-btn-file>input{
    position:absolute;
    top:0;
    right:0;
    margin:0;
    opacity:0;
    filter:alpha(opacity=0);
     float:right;
     width:50px;
     height:30px;
     cursor:pointer;
     font-size:23px;
    direction:ltr;
    cursor:pointer;
}
 .fileupload{
    margin-bottom:9px;
}
.fileupload .uneditable-input{
    display:inline-block;
    margin-bottom:0px;
    vertical-align:middle;
    cursor:text;
}
 .fileupload .thumbnail{
    overflow:hidden;
    display:inline-block;
    margin-bottom:5px;
    vertical-align:middle;
    text-align:center;
}
.fileupload .thumbnail>img{
    display:inline-block;
    vertical-align:middle;
    max-height:100%;
}
 .fileupload .btn{
    vertical-align:middle;
}
 .fileupload-exists .fileupload-new,.fileupload-new .fileupload-exists{
    display:none;
}
 .fileupload-inline .fileupload-controls{
    display:inline;
}
 .fileupload-new .input-append .btn-file{
    -webkit-border-radius:0 3px 3px 0;
    -moz-border-radius:0 3px 3px 0;
    border-radius:0 3px 3px 0;
}
 .thumbnail-borderless .thumbnail{
    border:none;
    padding:0;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none;
}
 .fileupload-new.thumbnail-borderless .thumbnail{
    border:1px solid #ddd;
}
 .control-group.warning .fileupload .uneditable-input{
    color:#a47e3c;
    border-color:#a47e3c;
}
 .control-group.warning .fileupload .fileupload-preview{
    color:#a47e3c;
}
 .control-group.warning .fileupload .thumbnail{
    border-color:#a47e3c;
}
 .control-group.error .fileupload .uneditable-input{
    color:#b94a48;
    border-color:#b94a48;
}
 .control-group.error .fileupload .fileupload-preview{
    color:#b94a48;
}
 .control-group.error .fileupload .thumbnail{
    border-color:#b94a48;
}
 .control-group.success .fileupload .uneditable-input{
    color:#468847;
    border-color:#468847;
}
 .control-group.success .fileupload .fileupload-preview{
    color:#468847;
}
 .control-group.success .fileupload .thumbnail{
    border-color:#468847;
}
 .clr_tr_bg_entry_form tr th {
     background-color:#F9F9F9 !important;
     border:solid 1px #E3E3E3;
}
 .clr_tr_bg_entry_form tr td {
     padding:5px !important;
}
 .clr_tr_bg_entry_form2 {
     border:solid 1px #E3E3E3 !important;
     background:#F9F9F9 ;
}
.custom_label{
	margin-top:20px !important;
}
 .clr_tr_bg_entry_form3 {
     border:none !important;
}
 .clr_tr_bg_entry_form3 tr {
     border:none !important;
}
 .clr_tr_bg_entry_form3 tr td {
     border:none;
     color:#7B7B7B !important;
     font-size:12px !important;
}
 .svg_tic_form_entry {
     text-align:right !important;
}
 .svg_tic_form_entry img {
     float:right;
}
 .approvel_new_open_table td table tr td {
     padding-bottom:6px !important;
     padding-top:6px !important;
     color:#7B7B7B !important;
     font-size:14px !important;
     text-align:left;
     white-space:nowrap;
}
 .approvel_new_open_table table {
     margin-bottom: 0px !important;
     color:#7B7B7B !important;
     font-size:12px !important;
}
 .approvel_new_open_table td {
     padding-top:10px !important;
     padding-bottom:10px !important;
}
 .check_box_pop {
     font-size:14px;
     font-weight:normal;
     float:left;
     padding-left: 0px;
}
 .checkmark {
     left: 60px;
     height: 20px;
     width: 20px;
}
 .check_box_pop .checkmark:after {
     left: 7px;
     top: 3px;
}
 .width_check_form_dtl_pop {
     width:25%;
     float:left;
}
 .width_check_form_dtl_pop2 {
     width:20%;
     float:left;
}
 .width_check_form_dtl_pop2 .checkmark {
     left: 38px;
}
 .width_check_form_dtl_pop3 {
     width:28%;
     float:left;
}
 .width_check_form_dtl_pop3 .checkmark {
     left: 70px;
}
 .width_check_form_dtl_pop4 {
     width:25%;
     float:left;
}
 .width_check_form_dtl_pop5 {
     width:22%;
     float:left;
}
 .width_check_form_dtl_pop5 .checkmark {
     left: 40px;
}
 .width_check_form_dtl_pop6 {
     width:30%;
     float:left;
}
 .width_check_form_dtl_pop6 .checkmark {
     left: 69px;
}
 .width_check_form_dtl_pop7 {
     width:30%;
     float:left;
}
 .mrg_top_from8 {
     margin-top: 7px;
}
 .left0_check {
     left: 0px !important;
}
 #musicinfo {
     display: none;
}
 @media (min-width: 320px) and (max-width: 767px){
     .check_box_pop .checkmark:after {
         left: 7px;
         top: 3px;
    }
     .width_check_form_dtl_pop {
         width:50%;
         float:left;
    }
     .width_check_form_dtl_pop2 {
         width:50%;
         float:left;
    }
     .width_check_form_dtl_pop2 .checkmark {
         left: 38px;
    }
     .width_check_form_dtl_pop3 {
         width:50%;
         float:left;
    }
     .width_check_form_dtl_pop3 .checkmark {
         left: 70px;
    }
     .width_check_form_dtl_pop4 {
         width:50%;
         float:left;
    }
     .width_check_form_dtl_pop5 {
         width:50%;
         float:left;
    }
     .width_check_form_dtl_pop5 .checkmark {
         left: 40px;
    }
     .width_check_form_dtl_pop6 {
         width:50%;
         float:left;
    }
     .width_check_form_dtl_pop6 .checkmark {
         left: 69px;
    }
     .width_check_form_dtl_pop7 {
         width:50%;
         float:left;
    }
     .approvel_new_open_table table th {
         font-size: 12px !important;
         white-space: nowrap;
         padding-left: 6px !important;
    }
     .custom_field_tab td {
         padding-left: 6px !important;
    }
}
 .mL30px{
     margin-left: 30px;
}
 .mR10px{
     margin-right: 10px
}
  .formtemplatedetail_btn_plus_cusotm {
    background: #ffffff;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    color: #989898 !important;
    margin-right: 15px !important;
    outline: none !important;
}
 .formtemplatedetail_btn_plus_cusotm:hover {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
 .formtemplatedetail_btn_plus_cusotm:focus {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
.formtemplatedetail_modal{
    padding-right: 40px;
}
.formtemplatedetail_approvel_field_table {
    border: none;
}
.btn_save_pro:hover {
    background: #ffffff !important;
    color: #357AAE;
    text-decoration: none;
    border: solid 1px #357AAE;
}
.btn_save_pro {
    background: #4358a7 !important;
}
.pen_custom_list_pop svg {
    position: relative;
    top:2px;
}

.label_dataentry{
    margin-left: 0 !important;
}
.FormtemplateDatePicker input
{
    border: none;
    font-size: 12px;
    text-align: end;
    width: 90px;
    height: 15px;
    padding: 12px;
    margin-left: -21px;
    margin-top: -8px
}
.tba-box {
    border: solid 1px #E6E6E6;
    /* margin-left: 15px; */
    width:188px
}
.pad-0 {
    padding: 0;
}
.new-chekbox {
    width: 20px !important;
    height: 20px !important;
}
.new-chekbox:after {
    left: 7px !important;
    top: 3px !important;
}
.form__display--props{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-self: center;
}
.form__date--picker{
    display: flex;
   
    align-self: center;
  
}
.Svg--icons--picker{
    width: 18px;
    margin-top: 5px;
    margin-left: 1rem;
        }
        .svg__color--blue{
            fill: #357aae;
        }
        /* .input--field--width{
            width: 55%;
        }
        .input--label--width{
            width: 30%;
        } */
        .form__display--between{
            justify-content: left !important;
        }