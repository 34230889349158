.error_page_logo
	{
		height:100vh;
	}
	  .error_page_logo img
	{
		width:500px;
				position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		    margin: auto;
	}
		  .label_right_error
	{
		width:500px;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		 margin: auto;
		 font-size:100px;
		 height:300px;
	}
		  .label_right_error span
	{
		font-size:18px;
		width:100%;
		    display: block;
	}
	.btn_backto_home
	{
		background:#fff;
		font-size:18px;
		color:#4358a7;
		text-align:center;
		width:180px;
		height:40px;
		line-height:38px;
		border-radius:5px;
		margin-top:20px;
		display:block;
		margin:auto;
		border:solid 1px #4358a7;
		text-decoration:none !important;
	}
	.btn_backto_home:hover
	{
		background:#4358a7;
		border:solid 1px #fff;
		font-size:16px;
		color:#fff;
	}
	.height_100_right
	{
		height:100% !important;
	}
	
	@media  (min-width: 768px) and (max-width: 1200px){
	  .error_page_logo img
	{
		width:80%;
	}	
		
		
		
		
	}
	@media  (min-width: 320px) and (max-width: 767px){
	  .error_page_logo img
	{
		width:70%;
		position:relative;
		margin-top:30px;
		margin-bottom:30px;
	}	
	.error_page_logo {
    height: auto;
}	
.label_right_error {
    width: 100%;
    position: relative;
    font-size: 100px;
    height: 200px;
	min-height:auto;
}
.login_right_error4
{
	min-height:400px;
	height:400px;
}
		
		
		
	}