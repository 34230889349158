
 .approvalsetup_OutlineNone{
     outline:none;
}
.textAlignCenter{
    text-align: center !important;
}
 .approvalsetup_heigtAuto{
     height: auto;
}
 .approvalsetup_OverflowAuto{
     overflow: auto;
}
 .approvalsetup_marginTop15px {
     margin-top:15px;
}
 .approvalsetup_approvel_field_table {
     border:none;
}
 .approvalsetup_approvel_field_table th {
     padding-left:20px !important;
}
 .approvalsetup_approvel_field_table td {
     padding-left:20px !important;
}
 .approvalsetup_approvel_field_table td img {
     margin-top:5px;
}
 .approvalsetup_approvel_field_table th {
     font-weight:600 !important;
     background:#29429d !important;
     color:#fff;
     font-size:15px;
     padding-left:20px !important;
}
 @media (min-width: 320px) and (max-width: 767px){
     .approvalsetup_approvel_field_table th {
         font-size:13px;
         white-space:nowrap;
    }
     .approvalsetup_approvel_field_table td {
         font-size:13px;
         white-space:nowrap;
    }
}
.cursorPointer{
    cursor:pointer;
}

.approval_setup_btn_plus_cusotm {
    background: #ffffff;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    color: #989898 !important;
    margin-right: 15px !important;
    outline: none !important;
}
 .approval_setup_btn_plus_cusotm:hover {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
 .approval_setup_btn_plus_cusotm:focus {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
.rotate180{
    transition: 300ms ease all;
    transform: rotate(180deg);
}