.forgetpassword_forgot_bg {
     background: #f3f3f3 !important;
     height: 100vh !important;
}
 #forgetpassword_marginRight10{
     margin-right: 10px !important;
}
.VerifyCodeNotActive{
        pointer-events: none;
    background: #94a5e4;
}

.resendCodeVerifyCode{
    float: right;
    cursor: pointer;
}
.vc_validation {
    color: #FF0000;
    margin-bottom: 0px;
    font-size: 12px;
    float: right;
    position: absolute;
}