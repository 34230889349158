.error_fp2{
    color:red;
}

.resend_label{
    cursor: pointer !important;
}
.fp2_validation {
    color: #FF0000;
    margin-bottom: 0px;
    font-size: 12px;
    float: right;
    position: absolute;
}