 .header_relitivePosition{
     position:relative;
}
 .header_dropdown_menu_dashset3 {
     position: absolute;
     top: 28px !important;
     margin-top: 0px !important;
     right: 230px !important;
     left: auto !important;
     margin-left: -11% !important;
     z-index: 1000;
     display: none;
     float: left;
     min-width: 100px;
     padding: 2px 0;
     margin: 2px 0 0;
     box-shadow: none;
}

 .header_dropdown_menu_dashset3>li>a {
     display: block;
     padding: 3px 10px !important;
}
 .header_dropdown_menu_dashset_down li a {
     color: #86878A;
     font-size: 16px;
     padding-top: 10px;
     border: none;
     text-align: left;
     float: left;
     padding-left: 0px !important;
     margin-left: 10px !important;
}
 .header_dropdown_menu_dashset_down li a svg {
     width: 16px;
     height: 16px;
     fill: #ACACAC;
     margin-top: 5px;
     margin-right: 8px;
     margin-bottom: -3px;
}
 .header_top_right{
     float: right !important;
     padding-top: 28px !important;
}
 .header_dropdown_menu_dashset_down {
     padding-bottom: 10px !important;
     padding-top: 10px !important;
}
 .header_CursorPointer{
     cursor: pointer;
}
 .header_mobileMenuStyling{
     width:25px !important;
     height:18px;
}
 .header_mobileMenuStylingRe{
     float:left;
     line-height:normal;
}
.company-name {
     color: #86878A;
     white-space: nowrap;
     float: right;
     font-size: 16px;
}
 @media (min-width: 768px) and (max-width: 991px)
{
    .header_dropdown_menu_dashset3 {
     position: absolute;
     top: 28px !important;
     margin-top: 0px !important;
     right: 163px !important;
     left: auto !important;
     margin-left: -11% !important;
     z-index: 1000;
     display: none;
     float: left;
     min-width: 100px;
     padding: 2px 0;
     margin: 2px 0 0;
     box-shadow: none;
}
}
 @media (min-width: 320px) and (max-width: 481px)
{
    .header_dropdown_menu_dashset3 {
     position: absolute;
     top: 28px !important;
     margin-top: 0px !important;
     right: 163px !important;
     left: auto !important;
     margin-left: -11% !important;
     z-index: 1000;
     display: none;
     float: left;
     min-width: 100px;
     padding: 2px 0;
     margin: 2px 0 0;
     box-shadow: none;
}
}
