.errors{
    color:red;
    float:right;
}

.resend-code{
    padding:10px;
    border-radius: 5px;
    background-color: #4358a7;
    color:white !important;
}