 .privacypolicy_mrg_dashboard_right {
     margin-top: 90px;
     transition:0.7s;
}

@media (min-width: 768px) and (max-width: 991px){
 .privacypolicy_mrg_dashboard_right {
         width: 83%;
    }
}
@media (min-width: 320px) and (max-width: 767px){
 .privacypolicy_mrg_dashboard_right {
     margin-top: 90px;
     transition:0.7s;
}
}