.help_borderNone{
     border:none;
}
 .help_mt5px{
     margin-top:5px;
}
 .help_bg_top {
     background: #fcfcfc !important;
     padding: 30px;
     padding-top: 60px;
     padding-bottom: 59px;
     text-align: center;
     font-size: 46px;
     color: #1C62B6;
     font-weight: 900;
}
 .help_bg_top_mrg {
     top: 89px !important;
}
 .help-panel-title> a {
     display: block;
     color: #29429D !important;
}
 .mR5px{
     margin-right: 10px;
}
 .help-panel-group .panel+.panel {
     margin-top: 5px;
}
 .help_panel_heading {
     color: #4358A7 !important;
     background-color: transparent !important;
     border-color: none !important;
     border-bottom: solid 1px #e6e6e6 !important;
     font-size: 20px !important;
     font-weight: bold !important;
}
 .help_panel_heading [data-toggle="collapse"].collapsed:after {
     -webkit-transform: rotate(90deg);
     -ms-transform: rotate(90deg);
     transform: rotate(90deg);
     color: #29429D  !important;
     content: "\F067";
}
 .help_carsoulcaption h3 {
     text-align: center;
     color: #29429D  !important;
     font-size: 30px;
     font-weight: bold;
     text-shadow: none !important;
}
 .btn_send_msg_help {
     background: #29429D !important;
     border-radius: 5px !important;
     text-align: center;
     width: 180px;
     height: 40px !important;
     line-height: 26px;
     font-size: 16px;
     color: #fff;
     display: block;
     text-decoration: none;
     border: solid 1px #357AAE;
     margin: auto;
}
 .help_panel_group {
     border: none;
     box-shadow: none !important;
}
.help_panel_heading
{
	color: #4358A7 !important;
background-color: transparent !important;
border-color: none !important;
border-bottom: solid 1px #e6e6e6 !important;
font-size:20px !important;
font-weight:bold !important;
}
.help_panel_heading .panel-title
{

font-size:20px !important;
font-family: Segoe UI !important;
font-weight:600;
}
.help_mrg_left_con {
    margin-left: 53px;
}
.clear30 {
    clear: both;
    height: 30px;
}
#myCarousel_help .carousel-indicators .active {
background-color: #858789 !important;
border: 1px solid #858789 !important;
width: 12px !important;
height: 12px !important;
}

#myCarousel_help .carousel-indicators li {
display: inline-block !important;
width: 12px !important;
height: 12px !important;
margin: 0px !important;
text-indent: -999px !important;
cursor: pointer !important;
background-color: #E6E6E6 !important;
border: 1px solid #E6E6E6 !important;
border-radius: 10px !important;
}

#myCarousel_help .carousel-indicators li {
margin-right: 10px !important;
}

#collapseEleven .tabbable-line > .nav-tabs > li.active > a {
border: 0;
color: #172E80;
font-size: 20px;
font-weight: 600 !important;
}

#collapseEleven .tabbable-line > .nav-tabs > li > a {
border: 0;
margin-right: 0;
color: #ACACAC;
text-align: center !important;
font-size: 20px;
font-weight: 600;
}

#collapseEleven .tabbable-line > .nav-tabs > li.active {
border-bottom: 1px solid #172E80;
position: relative;
font-size: 20px;
}

#collapseEleven .tabbable-line > .nav-tabs > li {
margin-right: 2px;
width: 49%;
}
#collapseEleven .nav-tabs>li {
float: left;
margin-bottom: 2px;
}

#collapseEleven .tabbable-line > .nav-tabs > li > a {
border: 0;
margin-right: 0;
color: #ACACAC;
text-align: center !important;
font-size: 20px;
font-weight: 600;
}

#collapseEleven .tabbable-panel {
border: 1px solid #eee;
padding: 10px;
text-align: center;
}#myCarousel_help .carousel-indicators .active {
background-color: #858789 !important;
border: 1px solid #858789 !important;
width: 12px !important;
height: 12px !important;
}

#myCarousel_help .carousel-indicators li {
display: inline-block !important;
width: 12px !important;
height: 12px !important;
margin: 0px !important;
text-indent: -999px !important;
cursor: pointer !important;
background-color: #E6E6E6 !important;
border: 1px solid #E6E6E6 !important;
border-radius: 10px !important;
}

.help_carousel_indicators {
bottom: 20px !important;
}

#myCarousel_help .carousel-indicators li {
margin-right: 10px !important;
}

#collapseEleven .tabbable-line > .nav-tabs > li.active > a {
border: 0;
color: #172E80;
font-size: 20px;
font-weight: 600 !important;
}

#collapseEleven .tabbable-line > .nav-tabs > li > a {
border: 0;
margin-right: 0;
color: #ACACAC;
text-align: center !important;
font-size: 20px;
font-weight: 600;
}

#collapseEleven .tabbable-line > .nav-tabs > li.active {
border-bottom: 1px solid #172E80;
position: relative;
font-size: 20px;
}

#collapseEleven .tabbable-line > .nav-tabs > li {
margin-right: 2px;
width: 49%;
}
#collapseEleven .nav-tabs>li {
float: left;
margin-bottom: 2px;
}

#collapseEleven .tabbable-line > .nav-tabs > li > a {
border: 0;
margin-right: 0;
color: #ACACAC;
text-align: center !important;
font-size: 20px;
font-weight: 600;
}

#collapseEleven .tabbable-panel {
border: 1px solid #eee;
padding: 10px;
text-align: center;
}
#collapseEleven .nav-tabs li {
 margin-bottom: 0px !important;   
}
#collapseEleven .nav-tabs li.active {
 margin-bottom: 0px !important;   
}
.help_textraea_contact{
    padding: 6px 12px 6px 40px !important;
}
@media (max-width: 767px) and (min-width: 320px){
.help_bg_top {
    padding-top: 150px !important;
    padding-bottom: 30px;
    font-size: 24px;
    width: 100%;
}
}
.btn_send_msg_help:hover {
     background:#fff !important;
     color:#29429d !important;
     border:solid 1px #29429d !important;
}