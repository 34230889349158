.bL0px{
     border-left:0px;
}

 #formpack_musicinfo {
     display: none;
}
 .formpack_approvel_field_table td img {
     margin-top: 5px;
}
 @media (min-width: 320px) and (max-width: 767px){
     .formpack_x_img_pack {
         margin-top:-3px !important;
         width:14px;
         height:16px;
    }
     .formpack_approvel_field_table {
         border: none;
         width: 500px;
    }
}
 .formpack_btn_plus_cusotm {
    background: #ffffff;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    color: #989898 !important;
    margin-right: 15px !important;
    outline: none !important;
}
 .formpack_btn_plus_cusotm:hover {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
 .formpack_btn_plus_cusotm:focus {
     background:#ffffff;
     border:solid 1px #c4c4c4;
     border-radius:5px;
     color:#989898 !important;
}
.mt5{
   margin-top: 5px;
}
.mt8{
   margin-top: 8px !important;
}
.mtMinus10{
   margin-top: -10px !important;
}
.approvel_field_table td img {
    margin-top: 5px;
}
.modal-dialog_w_pckform1 {
    width: 85%;
    margin: 30px auto;
}

#formPackModal .modal-dialog{
    width: 85%;
    margin: 30px auto;
}

.label_modal_custom
{
	overflow-y:scroll;
	height:500px;
}
.overflow_inherti_custom
{
	overflow:inherit !important;
	height:auto !important;
}


@media  (min-width: 320px) and (max-width: 767px){
.x_img_pack
{
	margin-top:-3px !important;
	width:14px;
	height:16px;
}
.approvel_field_table {
    border: none;
    width: 500px;
}
.modal-dialog_w_pckform1 {
    width: 96%;
}
#formPackModal .modal-dialog{
    width: 96%;
}
.btn_adduser_pck_pop
{
	display: block;
    margin-top: 10px;
    width: 140px;
}

.mrg_check_form_pack {
    margin-bottom: 25px;
}


.overflow_inherti_custom
{
	overflow-y:scroll !important;
	height:400px !important;
}
}

.checkbox_align .checkmark{
  /*top: -16px !important;*/
}
